import { InputHTMLAttributes } from 'react'
import { Field } from 'react-final-form'
import styles from './Engine.module.css'

export const languageOptions = [
  {
    label: 'English',
    value: 'English',
  },
  {
    label: 'Finnish',
    value: 'Finnish',
  },
  {
    label: 'Portuguese',
    value: 'Portuguese',
  },
  {
    label: 'Dutch',
    value: 'Dutch',
  },
]

export const themeOptions = [
  {
    label: 'Plain tasks',
    value: 'plain',
  },
  {
    label: 'News Desk',
    value: 'news_desk',
  },
  {
    label: 'Tapas Fiesta',
    value: 'tapas_fiesta',
  },
  {
    label: 'Delivery Agents',
    value: 'delivery',
  },
  {
    label: 'Company Triathlon',
    value: 'triathlon',
  },
  {
    label: 'Fully AI generated',
    value: 'random',
  },
]

export const themeOptionsWith3D = [
  {
    label: 'Plain tasks',
    value: 'plain',
  },
  {
    label: 'News Desk',
    value: 'news_desk',
  },
  {
    label: 'Tapas Fiesta',
    value: 'tapas_fiesta',
  },
  {
    label: 'Delivery Agents',
    value: 'delivery',
  },
  {
    label: 'Company Triathlon',
    value: 'triathlon',
  },
  {
    label: '3D News Studio',
    value: 'news3D',
  },
  {
    label: '3D ESG City',
    value: 'esg3D',
  },
  {
    label: 'Fully AI generated',
    value: 'random',
  },
]

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
}

export const FileField = ({ name, ...props }: Props) => (
  <Field<FileList> name={name}>
    {({ input: { value, onChange, ...input } }) => (
      <input
        className={styles.fileInput}
        {...input}
        type='file'
        onChange={({ target }) => onChange(target.files)} // instead of the default target.value
        {...props}
      />
    )}
  </Field>
)

export type SelectionOptions = {
  plot_lines: string[]
  image_urls: string[]
}

export type EngineRunState = {
  fileUploaded: boolean
  matchPairsCreated: boolean
  multiChoiceCreated: boolean
  checkBoxCreated: boolean
  missingWordCreated: boolean
  creativeCreated: boolean
  rawGameCreated: boolean
  firstGameChoiceCreated: boolean
  secondGameChoiceCreated: boolean
  thirdGameChoiceCreated: boolean
  boardImagesCreated: boolean
  selectionOptions: SelectionOptions | null
  selectedOption: boolean
  finalGameCreated: boolean
  storyLineCreated: boolean
  gameBoardAttached: boolean
  allReady: boolean
  gameId: number | null
  error: string | null
}

export type EngineRunStateItem = keyof EngineRunState

export enum EngineRunStateOrder {
  'fileUploaded' = 0,
  'matchPairsCreated' = 1,
  'multiChoiceCreated' = 2,
  'checkBoxCreated' = 3,
  'missingWordCreated' = 4,
  'creativeCreated' = 5,
  'rawGameCreated' = 6,
  'firstGameChoiceCreated' = 7,
  'secondGameChoiceCreated' = 8,
  'thirdGameChoiceCreated' = 9,
  'boardImagesCreated' = 10,
  'selectionOptions' = 11,
  'selectedOption' = 12,
  'finalGameCreated' = 13,
  'storyLineCreated' = 14,
  'gameBoardAttached' = 15,
  'allReady' = 16,
  'gameId' = 17,
  'error' = 18,
}

export const getRunState = (content: string): Partial<EngineRunState> => {
  let selectionOptions: SelectionOptions | null = null
  if (content.includes('Error occurred:')) {
    return {
      error: content.substring(content.indexOf('Error occurred')),
    }
  }
  if (content.includes('at createGame') || content.includes('TypeError')) {
    const lastErrorStart = content.lastIndexOf('<lastError>') + 11
    const lastError = content.substring(lastErrorStart, content.lastIndexOf('</lastError'))
    return {
      error: 'Error occurred. ' + lastError,
    }
  }
  if (content.includes('SELECTION_OPTIONS')) {
    const dataStart = content.indexOf('<SELECTION_OPTIONS>') + '<SELECTION_OPTIONS>'.length
    const optionsData = content.substring(dataStart, content.indexOf('<', dataStart))
    selectionOptions = JSON.parse(optionsData)
    if (selectionOptions)
      selectionOptions.image_urls = selectionOptions.image_urls.map((url) =>
        url.includes('https://')
          ? url
          : `${process.env.REACT_APP_ENGINE_BACKEND}${url}`.replaceAll('https://seppo-proxy.herokuapp.com/', ''),
      )
  }
  return {
    fileUploaded: content.includes('Material File Id '),
    matchPairsCreated: content.includes('Match pairs tasks created'),
    multiChoiceCreated: content.includes('Multichoice tasks created'),
    checkBoxCreated: content.includes('Checkbox tasks created'),
    missingWordCreated: content.includes('Missing word tasks created'),
    creativeCreated: content.includes('Creative tasks created'),
    rawGameCreated: content.includes('Game created'),
    firstGameChoiceCreated: content.includes('--Tasks1--'),
    secondGameChoiceCreated: content.includes('--Tasks2--'),
    thirdGameChoiceCreated: content.includes('--Tasks3--'),
    boardImagesCreated: !!selectionOptions,
    selectionOptions: selectionOptions,
    selectedOption: content.includes('Selected option:'),
    finalGameCreated: content.includes('SELECTED 12 TASKS'),
    storyLineCreated: content.includes('SELECTED 12 TASKS'),
    gameBoardAttached: content.includes('SELECTED 12 TASKS'),
    gameId: content.includes('Game created true') ? parseInt(content.split('Game created true ')[1]) : null,
    allReady: content.includes('Game created true'),
  }
}

export const INSTRUCTIONS_DEFAULT = `<ol>
    <li>Engine creates two games. One with 40 'raw' tasks and one with ~12 tasks. </li>
    <li>You can add a storyline to the 12 tasks game, selecting from:</li>
    <ul>
      <li>
        <strong>News Desk:</strong> A news office themed game
      </li>
      <li>
        <strong>Tapas Fiesta:</strong> A fiesta themed game
      </li>
      <li>
        <strong>Delivery agents:</strong> Learning while doing a parcel delivery
      </li>
      <li>
        <strong>Company Triathlon:</strong> Sports themed game mostly for strategy & values learning
      </li>
      <li>
        <strong>Fully AI generated:</strong> Seppo-Engine studies the material and provides three game options to
        choose from
      </li>
    </ul>
  </ol>
  <ol start={3}>
    <li>You can also choose to not bundle a story to the 12 task game.</li>
    <li>
      The generated game will be shared to your Seppo account.
    </li>
    <li>You will have three gameboard pictures to choose from.</li>
    <li>Game generation takes about 10 minutes.</li>
  </ol>`
