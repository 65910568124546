import * as React from 'react'
import { SVGProps } from 'react'

const SvgSidebarPinFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' width='100%' height='100%' viewBox='3 4 32 32' xmlns='http://www.w3.org/2000/svg'>
    <filter id='a' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse' height='7' width='16' x='11' y='32'>
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feBlend in='SourceGraphic' in2='BackgroundImageFix' mode='normal' result='shape' />
      <feGaussianBlur result='effect1_foregroundBlur_3193_315' stdDeviation='1.5' />
    </filter>
    <filter
      id='b'
      color-interpolation-filters='sRGB'
      filterUnits='userSpaceOnUse'
      height='42.0298'
      width='36.77'
      x='.514186'
      y='.342791'
    >
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        result='hardAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
      />
      <feOffset dy='1.8286' />
      <feGaussianBlur stdDeviation='2.74291' />
      <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
      <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_3193_315' />
      <feBlend in='SourceGraphic' in2='effect1_dropShadow_3193_315' mode='normal' result='shape' />
    </filter>
    <linearGradient id='c' gradientUnits='userSpaceOnUse' x1='18.8992' x2='18.8992' y1='4' y2='35.0582'>
      <stop offset='0' stop-color='#fff' />
      <stop offset='1' stop-color='#fff' stop-opacity='0' />
    </linearGradient>
    <g filter='url(#b)'>
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='currentColor'
      />
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='url(#c)'
        fill-opacity='.25'
      />
    </g>
    <g fill='#fff'>
      <path d='m13.5 10.8296v12.8058c0 .1591.053.4773.1326.5038.3446.0795.7423.106 1.087.053.1061 0 .2121-.3712.2121-.5568v-12.7793c0-.6098-.053-.6363-.7424-.6363-.6628 0-.6893.0265-.6893.6098z' />
      <path d='m25.5522 10.6609c-.0072-.0965-.0152-.2037-.0152-.3351-.1591.1061-.2652.1856-.3447.2652-.7424.6893-1.6173.9809-2.6248.7688-.7156-.1683-1.4479-.3868-2.1704-.6024-.1876-.056-.3746-.1118-.5605-.1664-1.3256-.3977-2.5717-.4243-3.6323.5832-.2121.1856-.3711.5038-.3711.7689-.0398 1.3911-.0348 2.7673-.0299 4.151.0017.4623.0033.9255.0033 1.3903 0 .1325 0 .2916.0266.5302 1.087-1.14 2.3066-1.4052 3.7118-.9544.2055.0662.4109.1342.6164.2021.6164.2038 1.2329.4077 1.8493.5667.8484.2387 1.6703.2387 2.4657-.1856.6629-.3711 1.1666-.7954 1.1136-1.6968-.0568-1.2111-.046-2.4087-.0352-3.6121.0043-.4826.0087-.9662.0087-1.4519 0-.0715-.0054-.143-.0113-.2217z' />
    </g>
  </svg>
)

export default SvgSidebarPinFlag
