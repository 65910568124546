import {
  ApiResponse,
  Badge,
  CheckboxSubtask,
  CreativeAnswerOption,
  CreativeSubtask,
  ExploreSubtask,
  MatchPairsSubtask,
  MissingWordSubtask,
  MultipleChoiceSubtask,
  ObjectAny,
  PointsType,
  Subtask,
  Task,
  TaskAdvanced,
  Try,
  toFailure,
  toSuccess,
} from '../types/commonTypes'
import { getRandomTempId } from '../util/functional'
import { getHoursMinutesSecondsFromSeconds } from '../util/number'
import { safeIsNullOrEmpty } from '../util/string'
import { createAxios } from './axios'
import {
  BaseExercise,
  CreateTaskResponse,
  ExerciseType,
  GradeAnswerResponse,
  SearchTaskLibraryParams,
  SearchTaskLibraryResponse,
  TaskIconType,
} from './gameTypes'
import { parseBadgeVmToBadgeData } from './typeConverters'
import { TEST_USER_AUTHORIZATION } from './userApiService'

const parseCreativeTaskSubmitData = (subtask: CreativeSubtask, noPointsGame?: boolean): ObjectAny => {
  return {
    points: noPointsGame ? 1 : subtask.data.pointsForAnswer,
    allows_text: subtask.data.answerOptions.includes(CreativeAnswerOption.Text),
    allows_image: subtask.data.answerOptions.includes(CreativeAnswerOption.Images),
    allows_video: subtask.data.answerOptions.includes(CreativeAnswerOption.Video),
    allows_audio: subtask.data.answerOptions.includes(CreativeAnswerOption.Audio),
    has_default_feedback: subtask.data.hasAutomatedFeedback,
    default_feedback: subtask.data.hasAutomatedFeedback ? subtask.data.automatedFeedback : null,
    has_reference_answer: subtask.data.hasEvaluationGuideline,
    reference_answer: subtask.data.hasEvaluationGuideline ? subtask.data.evaluationGuideline : null,
    // TODO: currently no way to remove automatic points percentage if it was set, pending some product decisions
    // on how to handle auto_score_percentage without has_default_feedback and default_feedback
    auto_score_percentage:
      subtask.data.pointsType === PointsType.Automatic
        ? subtask.data.autoScoreOption === 'other'
          ? subtask.data.autoScorePercentage?.toString()
          : subtask.data.autoScoreOption
        : subtask.data.hasAutomatedFeedback
        ? '0'
        : undefined,
  }
}

const parseCheckboxTaskSubmitData = (subtask: CheckboxSubtask, noPointsGame?: boolean): ObjectAny => {
  const answersMapped = subtask.data.answers.reduce(
    (
      data: { choices: string[]; points: number[]; picture_urls: string[]; next_ex_ids: null[]; feedbacks: string[] },
      answer,
    ) => {
      return {
        choices: [...data.choices, answer.text || ''],
        points: [...data.points, noPointsGame ? (answer.isCorrectAnswer ? 1 : 0) : answer.points ?? 0],
        picture_urls: [...data.picture_urls, answer.imageUrl ?? ''],
        next_ex_ids: [...data.next_ex_ids, null],
        feedbacks: [...data.feedbacks, ''],
      }
    },
    { choices: [], points: [], picture_urls: [], next_ex_ids: [], feedbacks: [] },
  )
  return {
    data: {
      ...answersMapped,
      correct_index: '',
      correct: '',
      has_default_feedback: subtask.data.hasAutomatedFeedback,
      feedback: subtask.data.hasAutomatedFeedback ? subtask.data.automatedFeedback : '',
    },
    has_default_feedback: subtask.data.hasAutomatedFeedback,
    default_feedback: subtask.data.hasAutomatedFeedback ? subtask.data.automatedFeedback : '',
    points: answersMapped.points.reduce((sum, currPoints) => sum + Math.max(currPoints, 0), 0),
  }
}

const parseMultipleChoiceTaskSubmitData = (subtask: MultipleChoiceSubtask, noPointsGame?: boolean): ObjectAny => {
  const answersMapped = subtask.data.answers.reduce(
    (
      data: { choices: string[]; points: number[]; picture_urls: string[]; next_ex_ids: string[]; feedbacks: string[] },
      answer,
    ) => {
      return {
        choices: [...data.choices, answer.text || ''],
        points: [...data.points, noPointsGame ? (answer.isCorrectAnswer ? 1 : 0) : answer.points ?? 0],
        picture_urls: [...data.picture_urls, answer.imageUrl ?? ''],
        next_ex_ids: [...data.next_ex_ids, answer.nextTaskId ?? '0'],
        feedbacks: [...data.feedbacks, answer.hasFeedback ? answer.feedback ?? '' : ''],
      }
    },
    { choices: [], points: [], picture_urls: [], next_ex_ids: [], feedbacks: [] },
  )
  return {
    data: {
      ...answersMapped,
      correct_index: subtask.data.answers.map((answer) => answer.isCorrectAnswer ?? false).indexOf(true),
      correct: subtask.data.answers.find((answer) => answer.isCorrectAnswer)?.text ?? '',
    },
    points: Math.max(...answersMapped.points.map((p) => Math.max(p, 0))),
  }
}

const parseMatchPairsTaskSubmitData = (subtask: MatchPairsSubtask, noPointsGame?: boolean): ObjectAny => {
  const answers = subtask.data.answers.reduce((answers: ObjectAny[], answer) => {
    return [
      ...answers,
      {
        temp_id: getRandomTempId(),
        answer: {
          leftText: answer.left.text || '',
          leftImage: answer.left.imageUrl,
          rightText: answer.right.text || '',
          rightImage: answer.right.imageUrl,
        },
        points: noPointsGame ? 1 : answer.points,
        feedback: '',
      },
    ]
  }, [])
  return {
    questions: [
      {
        temp_id: getRandomTempId(),
        question_title: 'Question 1',
        question: subtask.description,
        answers,
      },
    ],
    points: answers.reduce((sum, currAnswer) => sum + Math.max(currAnswer.points, 0), 0),
  }
}

const parseMissingWordTaskSubmitData = (subtask: MissingWordSubtask, noPointsGame?: boolean): ObjectAny => {
  const answers = subtask.data.answers.reduce((answers: ObjectAny[], answer) => {
    return [
      ...answers,
      {
        temp_id: getRandomTempId(),
        answer: answer.word,
        points: noPointsGame ? 1 : answer.points,
      },
    ]
  }, [])
  return {
    questions: [
      {
        temp_id: getRandomTempId(),
        question_title: 'Question 1',
        question: subtask.description,
        answers,
      },
    ],
    points: answers.reduce((sum, currAnswer) => sum + Math.max(currAnswer.points, 0), 0),
  }
}

const parseExploreTaskSubmitData = (subtask: ExploreSubtask, noPointsGame?: boolean): ObjectAny => {
  return {
    grading_mode: subtask.data.ackRequired ? 'ACK_REQUIRED' : '',
  }
}

const parseAdvancedSettings = (advanced: TaskAdvanced): Partial<BaseExercise> => {
  const [hours, minutes, seconds] = getHoursMinutesSecondsFromSeconds(advanced.timeLimitSeconds ?? 0)
  return {
    is_flash: advanced.isFlash,
    is_hidden: advanced.isHidden,
    requires_postcard: advanced.requirePictureOrSelfie,
    has_time_pressure: advanced.hasTimeLimit,
    ...(advanced.hasTimeLimit &&
      advanced.timeLimitSeconds && {
        time_pressure_hours: hours,
        time_pressure_minutes: minutes,
        time_pressure_seconds: seconds,
      }),
    has_proximity: advanced.hasProximityLock,
    ...(advanced.hasProximityLock &&
      advanced.requiredProximity && { required_proximity: parseInt(advanced.requiredProximity) }),
    has_lock_code: advanced.hasLockCode,
    ...(advanced.hasLockCode && advanced.lockCode && { lock_code: advanced.lockCode }),
    ...(advanced.hasLockCode && advanced.lockClue && { code_hint: advanced.lockClue }),
    icon_type: advanced.hasCustomIconType && advanced.iconType ? advanced.iconType : TaskIconType.seppo,
    badge_auto_enabled: advanced.hasAutomaticBadge,
    ...(advanced.hasAutomaticBadge && advanced.automaticBadgeId && { badge_id: advanced.automaticBadgeId }),
    ...(advanced.hasAutomaticBadge &&
      advanced.automaticBadgePoints != null && { badge_points_req: advanced.automaticBadgePoints }),
  }
}

const parseSubtaskVmToTaskData = (
  gameId: number,
  task: Task,
  subtask: Subtask,
  isFirst?: boolean,
  noPointsGame?: boolean,
): ObjectAny => {
  return {
    id: subtask.id,
    name: task.name,
    x: task.x,
    y: task.y,
    map_id: isFirst && task.level === 0 ? -1 : (task.mapIndex ?? 0) - 1,
    type: subtask.type,
    description: subtask.description,
    game_id: gameId,
    temp_id: subtask.tempId,
    ...(subtask.type === ExerciseType.CreativeExercise && parseCreativeTaskSubmitData(subtask, noPointsGame)),
    ...(subtask.type === ExerciseType.PollExercise && parseCheckboxTaskSubmitData(subtask, noPointsGame)),
    ...(subtask.type === ExerciseType.MultichoiceExercise && parseMultipleChoiceTaskSubmitData(subtask, noPointsGame)),
    ...(subtask.type === ExerciseType.CombineExercise && parseMatchPairsTaskSubmitData(subtask, noPointsGame)),
    ...(subtask.type === ExerciseType.MissingWordExercise && parseMissingWordTaskSubmitData(subtask, noPointsGame)),
    ...(subtask.type === ExerciseType.ExploreExercise && parseExploreTaskSubmitData(subtask, noPointsGame)),
    ...(isFirst && task.advanced != null && parseAdvancedSettings(task.advanced)),
    ...(isFirst && { open: task.id == null || task.id === -1 ? !task.advanced.isFlash : task.isOpen }),
    ...(isFirst && { level: task.level }),
    ...(isFirst && { order_number: task.order }),
  }
}

export async function moveTaskApi({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  exerciseId,
  x,
  y,
}: {
  authorization?: null
  gameId: number
  exerciseId: number
  x: number
  y: number
}): Promise<Try<CreateTaskResponse>> {
  try {
    const response = await createAxios(authorization).put<CreateTaskResponse>(
      `/admin/games/${gameId}/exercises/${exerciseId}.json`,
      {
        game_id: gameId,
        exercise_id: exerciseId,
        exercise: {
          x,
          y,
        },
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

export async function createOrUpdateTaskApi({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  task,
  noPointsGame,
  subtasksToDelete,
}: {
  authorization: null
  gameId: number
  task: Task
  noPointsGame?: boolean
  subtasksToDelete: number[]
}): Promise<Try<CreateTaskResponse>> {
  try {
    if (task.subtasks.some((subtask) => subtask.type === ExerciseType.CollaborationExercise)) {
      throw new Error('Create and comment task not implemented yet!')
    }
    const response = await createAxios(authorization).post<CreateTaskResponse>(
      `/admin/games/${gameId}/exercises/0/create_or_update_group.json`,
      {
        ux3: true,
        game_id: gameId,
        is_copy: false,
        exs_to_delete: JSON.stringify(subtasksToDelete),
        exercise_id: 0,
        exercises: JSON.stringify(
          task.subtasks.map((subtask, index) =>
            parseSubtaskVmToTaskData(gameId, task, subtask, index === 0, noPointsGame),
          ),
        ),
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

export async function deleteTask({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  exerciseId,
}: {
  authorization?: null
  gameId: number
  exerciseId: number
}): Promise<Try<void>> {
  try {
    const response = await createAxios(authorization).delete<void>(
      `/admin/games/${gameId}/exercises/${exerciseId}.json`,
      {},
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

export async function genericUpdateTaskAttributes({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  exercise,
}: {
  authorization?: null
  gameId: number
  exercise: Partial<BaseExercise>
}): Promise<Try<void>> {
  try {
    const response = await createAxios(authorization).put<void>(
      `/admin/games/${gameId}/exercises/${exercise.id}.json`,
      {
        exercise: {
          game_id: gameId,
          ...exercise,
        },
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

//Request backend to push the latest version of an answer
//For now used to get previous answers
export async function requestAnswerRefresh({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  answerId,
}: {
  authorization?: null
  gameId: number
  answerId: number
}): Promise<Try<ApiResponse>> {
  try {
    const response = await createAxios(authorization).get<ApiResponse>(
      `/admin/games/${gameId}/answers/${answerId}/trigger_push_update.json`,
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

//gradeAnswer must be called separately for each creative subtask answer if there are multiple
export async function sendAnswerGrading({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  answerId,
  comment,
  points,
  badge,
}: {
  authorization?: null
  gameId: number
  answerId: number
  comment: string
  points: number
  badge?: Badge
}): Promise<Try<GradeAnswerResponse>> {
  try {
    const response = await createAxios(authorization).post<GradeAnswerResponse>(
      `/admin/games/${gameId}/answers/${answerId}/grade.json`,
      {
        comment: comment,
        points: points,
        more_time_seconds: 0, //Field not used for now, but legacy UI sends it always
        ...(badge != null && { badge: JSON.stringify(parseBadgeVmToBadgeData(badge)) }),
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

/*
Only one request for the 'parent' answer
https://staging.seppo.io/admin/games/3874/answers/19370/give_back.json

Comments and points are in the format:
comments[19370]: Voi s**tana pojat
comments[19371]: Yrittäkää vähän 😢 
comments[19372]: 
points[19370]: 0
points[19371]: 2
points[19372]: 0

Backend also requires a children structure of the child answers like
children: [{"id":19371,"comment":"Yrittäkää vähän 😢 ","point":"2"},{"id":19372,"comment":"","point":0}]

*/
export async function sendAnswerForRevision({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  parentAnswerId,
  comments,
  points,
  moreTimeSeconds = 0,
  forceNotification = false,
}: {
  authorization?: null
  gameId: number
  parentAnswerId: number
  comments: { [key: string]: string }
  points: { [key: string]: number }
  moreTimeSeconds?: number
  forceNotification?: boolean
}): Promise<Try<GradeAnswerResponse>> {
  const children: {}[] = []
  if (Object.keys(comments).length > 1) {
    Object.keys(comments).forEach((key, ind) => {
      if (ind > 0) children.push({ id: key, comment: comments[key], point: points[key] })
    })
  }
  try {
    const response = await createAxios(authorization).post<GradeAnswerResponse>(
      `/admin/games/${gameId}/answers/${parentAnswerId}/give_back.json`,
      {
        comments,
        points,
        children: JSON.stringify(children),
        forceNotification: !forceNotification && moreTimeSeconds > 0 ? true : false,
        more_time_seconds: moreTimeSeconds,
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

export const searchTaskLibraryApi = async ({
  authorization = TEST_USER_AUTHORIZATION,
  page,
  perPage,
  age,
  language,
  subject,
  search,
  taskType,
  librarySource,
  gameId,
}: SearchTaskLibraryParams): Promise<Try<SearchTaskLibraryResponse>> => {
  try {
    const libParams =
      librarySource == null
        ? { private: true, include_public: true } // search both
        : librarySource === 'private'
        ? { private: true } // search private only
        : {} // no param - search public only
    const response = await createAxios(authorization).get<SearchTaskLibraryResponse>(
      '/admin/exercises/library_data.json',
      {
        params: {
          ux3: true,
          page,
          per_page: perPage,
          game_id: gameId,
          ...(age != null && { age }),
          ...(subject != null && { subject }),
          ...(language != null && { language }),
          ...(taskType != null && { type: taskType }),
          ...libParams,
          ...(!safeIsNullOrEmpty(search) && { search }),
        },
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}
