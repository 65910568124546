import { TFunction } from 'i18next'
import { ExerciseType, MapType, TaskIconType } from '../api/gameTypes'
import { IconName, getIcon } from '../common/components/icons/utils'
import { EmptyContentBrowseButton, EmptyContentProps } from '../composites/GamesOverview/components/EmptyContent'
import { hasTrialPeriodExpired } from '../composites/WelcomeBlock/helpers'
import { routes } from '../routes'
import { Game, Subtask, TUser, Task } from '../types/commonTypes'
import { safeParseInt } from './number'
import { getCurrentIsoDate, safeIsNullOrEmpty } from './string'

export const getDisplayGameOwnersLines = (gameOwners: string[]): string[] => {
  return gameOwners?.length <= 2 ? gameOwners : [...gameOwners.slice(0, 2), `+ ${gameOwners.length - 2}`]
}

export const getDisplayGameOwnersString = (gameOwners: string[]): string => {
  return gameOwners?.length <= 2
    ? gameOwners.join(', ')
    : `${gameOwners.slice(0, 2).join(', ')} + ${gameOwners.length - 2}`
}

export const getDisplayExercisesAndMaps = (exercisesCount: number, mapsCount: number, t: TFunction) => {
  const exercises = t('game_info.tasks', { defaultValue: '%{count} tasks', count: exercisesCount })
  const maps = t('game_info.game_board', { defaultValue: '%{count} game boards', count: mapsCount })
  return `${exercises}, ${maps}`
}

export const preventUserCreateGame = (user: TUser | null): boolean => {
  return (
    (user?.isTrial && hasTrialPeriodExpired(user?.createdAt)) ||
    !user?.activeBusiness.validUntil ||
    (user?.activeBusiness.validUntil != null && user.activeBusiness.validUntil.toString() < getCurrentIsoDate())
  )
}

export const preventUserStartGame = (user: TUser | null, game: Game | undefined): boolean => {
  return preventUserCreateGame(user) && (game?.validUntil == null || game.validUntil < getCurrentIsoDate())
}

export const getEmptyContentBrowseButton = (user: TUser | null, t: TFunction): EmptyContentBrowseButton | undefined => {
  return user?.hasCommunity
    ? { label: t('dashboard.empty.browse_community_button', 'Browse games in Community'), route: routes.community }
    : user?.hasTemplates
    ? { label: t('dashboard.empty.browse_templates_button', 'Browse games in Templates'), route: routes.templates }
    : user?.gamePackages
    ? {
        label: t('dashboard.empty.browse_libraries_button', 'Browse games in Library'),
        route: routes.createGamePackageWithId(user?.gamePackages?.[0].id ?? 0),
      }
    : undefined
}

export const getDashboardEmptyContent = (user: TUser | null, t: TFunction): EmptyContentProps => {
  const subtitle = user?.hasCommunity
    ? t('dashboard.empty.content.with_community', 'Create a new game or visit our community for inspiration')
    : user?.hasTemplates
    ? t('dashboard.empty.content.with_templates', 'Create a new game or check out our templates for inspiration')
    : user?.gamePackages
    ? t('dashboard.empty.content.with_packages', 'Check out the Library to get started with own games')
    : t('dashboard.empty.content.without_libraries', 'Create a new game')

  return {
    title: t('dashboard.empty.title', 'You have no games yet'),
    subtitle,
    browseButton: getEmptyContentBrowseButton(user, t),
  }
}

export const getLevelIcon = (level: number): JSX.Element => {
  if (safeIsNullOrEmpty(level) || level === 0 || level > 10) {
    return getIcon('levelGeneric')
  }
  return getIcon(`level${level}` as IconName)
}

export const getLevelTitle = (level: number, t: TFunction): string => {
  return level === 0
    ? t('game_editor.levels.first_tasks_title', 'First tasks')
    : t('game_editor.levels.level_with_number', {
        defaultValue: 'Level %{level_number}',
        level_number: level,
      })
}

export const getTaskIcon = (task: Task | Omit<Task, 'subtasks'>, hasLevels?: boolean): JSX.Element => {
  if (task.advanced.isFlash) {
    return getIcon('taskFlash')
  } else if (hasLevels) {
    return getLevelIcon(task.level ?? 1)
  } else if (task.isCrossing) {
    return getIcon('sidebarPinCrossing')
  } else if (task.advanced.hasProximityLock) {
    return getIcon('sidebarPinLocked')
  } else if (task.advanced.hasCustomIconType && task.advanced.iconType === TaskIconType.three_stars) {
    return getIcon('sidebarPinThreeStars')
  } else if (task.advanced.hasCustomIconType && task.advanced.iconType === TaskIconType.two_stars) {
    return getIcon('sidebarPinTwoStars')
  } else if (task.advanced.hasCustomIconType && task.advanced.iconType !== TaskIconType.seppo) {
    const pinName = ('sidebarPin' +
      String(task.advanced.iconType).charAt(0).toUpperCase() +
      String(task.advanced.iconType).slice(1)) as IconName
    return getIcon(pinName)
  }

  return getIcon('exercisePin')
}

const getAnswersPointsSum = (answers: Array<{ points?: number | undefined }>) => {
  return (answers || []).reduce((sum: number | undefined, answer) => {
    const points = safeParseInt(answer.points)
    if (points != null) {
      return (sum ?? 0) + Math.max(points, 0)
    }
    return sum
  }, undefined)
}

export const getTotalPointsForSubtask = (subtask: Subtask): number | undefined => {
  switch (subtask.type) {
    case ExerciseType.CreativeExercise:
      return subtask.data.pointsForAnswer
    case ExerciseType.MultichoiceExercise:
      return (subtask.data.answers || []).some((answer) => answer.points != null)
        ? Math.max(...(subtask.data.answers || []).map((answer) => answer.points ?? 0))
        : undefined
    case ExerciseType.CollaborationExercise:
      return undefined
    case ExerciseType.ExploreExercise:
      return undefined
    default:
      return getAnswersPointsSum(subtask.data.answers)
  }
}

export const getTotalPointsForTask = (task: Task): number | undefined => {
  return (task.subtasks || []).reduce((points: number | undefined, subtask) => {
    const subtaskPoints = getTotalPointsForSubtask(subtask)
    if (subtaskPoints != null) {
      return (points ?? 0) + subtaskPoints
    }
    return undefined
  }, undefined)
}

export const getTotalPointsForTasks = (tasks: Task[]): number => {
  return tasks.reduce((pointsSum, task) => {
    return pointsSum + (getTotalPointsForTask(task) ?? 0)
  }, 0)
}

export const hasSeveralBoards = (game: Game): boolean => {
  return game.gameBoardSettings.gameBoardType !== MapType.LIVE && game.gameBoardSettings.gameBoards.length > 1
}

export const levelOrderTasksSort = (taskA: Task, taskB: Task) => {
  if ((taskA.level ?? 1) !== (taskB.level ?? 1)) {
    return (taskA.level ?? 1) - (taskB.level ?? 1)
  } else if ((taskA.mapIndex ?? 0) !== (taskB.mapIndex ?? 0)) {
    return (taskA.mapIndex ?? 0) - (taskB.mapIndex ?? 0)
  } else if (taskA.order === taskB.order) {
    return taskA.id - taskB.id
  }
  return taskA.order == null ? 1 : taskB.order == null ? -1 : taskA.order - taskB.order
}

export const orderTasksSort = (taskA: Task, taskB: Task) => {
  if ((taskA.mapIndex ?? 0) !== (taskB.mapIndex ?? 0)) {
    return (taskA.mapIndex ?? 0) - (taskB.mapIndex ?? 0)
  } else if (taskA.order === taskB.order) {
    return taskA.id - taskB.id
  }
  return taskA.order == null ? 1 : taskB.order == null ? -1 : taskA.order - taskB.order
}

export const flashTasksSort = (taskA: Task, taskB: Task): number => {
  if (taskA.flashOrder === taskB.flashOrder) {
    return taskA.id - taskB.id
  }
  return taskA.flashOrder == null ? 1 : taskB.flashOrder == null ? -1 : taskA.flashOrder - taskB.flashOrder
}
