import classNames from 'classnames'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { PointsPill } from '../../../../../../../common/components/PointsPill/PointsPill'
import { AnswerStateEnum } from '../../../../../../../types/commonTypes'
import { getPlayerCardFocusableElementId } from '../../../../../helpers'
import { getEvaluationStateIcon, getInitials, getPillLabel, getPillState } from './helpers'

import styles from './PlayerCard.module.css'

type PlayerCardProps = {
  id: number
  isSelected: boolean
  playerName: string
  points: number
  state: AnswerStateEnum
  noPointsGame?: boolean
  isExplore?: boolean
  isCompact: boolean
  onClickPlayer: (id: number) => void
}

export const PlayerCard: React.FC<PlayerCardProps> = ({
  id,
  isSelected,
  playerName,
  points,
  state,
  noPointsGame,
  isExplore,
  isCompact,
  onClickPlayer,
}) => {
  const { t } = useTranslation()
  const onClickPlayerInternal = useCallback(() => {
    onClickPlayer(id)
  }, [id, onClickPlayer])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>) => {
      if (e.key === 'Enter') {
        onClickPlayerInternal()
      }
    },
    [onClickPlayerInternal],
  )

  return (
    <li
      className={classNames(
        styles.container,
        isCompact && styles.container_compact,
        styles[`container_state_${state}`],
        isSelected && styles.container_selected,
      )}
      onClick={onClickPlayerInternal}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      id={getPlayerCardFocusableElementId(id)}
    >
      <div className={styles.playerInitials}>{getInitials(playerName)}</div>
      <div className={styles.playerAndTaskInfo}>
        <h5 className={styles.playerName}>{playerName}</h5>
        <div className={styles.pointsAndState}>
          {!noPointsGame && !isExplore && (
            <PointsPill label={getPillState(state, points)} size='small' noIcon state={state} />
          )}
          <p className={styles.taskStateLabel}>{getPillLabel(state, t, isExplore)}</p>
        </div>
      </div>
      <div className={styles.stateIcon}>{getEvaluationStateIcon(state)}</div>
    </li>
  )
}
