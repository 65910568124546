import * as React from 'react'
import { SVGProps } from 'react'

const SvgSidebarPinCrown = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' width='100%' height='100%' viewBox='3 4 32 32' xmlns='http://www.w3.org/2000/svg'>
    <filter id='a' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse' height='7' width='16' x='11' y='32'>
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feBlend in='SourceGraphic' in2='BackgroundImageFix' mode='normal' result='shape' />
      <feGaussianBlur result='effect1_foregroundBlur_3193_200' stdDeviation='1.5' />
    </filter>
    <filter
      id='b'
      color-interpolation-filters='sRGB'
      filterUnits='userSpaceOnUse'
      height='42.0298'
      width='36.77'
      x='.514186'
      y='.342791'
    >
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        result='hardAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
      />
      <feOffset dy='1.8286' />
      <feGaussianBlur stdDeviation='2.74291' />
      <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
      <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_3193_200' />
      <feBlend in='SourceGraphic' in2='effect1_dropShadow_3193_200' mode='normal' result='shape' />
    </filter>
    <linearGradient id='c' gradientUnits='userSpaceOnUse' x1='18.8992' x2='18.8992' y1='4' y2='35.0582'>
      <stop offset='0' stop-color='#fff' />
      <stop offset='1' stop-color='#fff' stop-opacity='0' />
    </linearGradient>
    <g filter='url(#b)'>
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='currentColor'
      />
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='url(#c)'
        fill-opacity='.25'
      />
    </g>
    <g fill='#fff'>
      <path d='m25.5081 13.2052c.1223-.0978.2936-.0978.4159-.0244.1224.0734.1958.2202.1713.367l-.5872 4.8934c0 .0979-.0489.1958-.1223.2447-1.1255.9787-3.5478 1.5659-6.2881 1.5659-2.7404 0-5.1382-.6117-6.2881-1.5659-.0734-.0734-.1224-.1468-.1224-.2447l-.5872-4.8934c0-.1468.0734-.2936.1957-.367.1224-.0734.2937-.0734.416.0244 0 0 .9787.7585 2.202.7585 1.6149 0 2.8872-1.2478 3.8659-3.719.0489-.1468.1957-.2447.3425-.2447s.2936.0979.3426.2447c.9542 2.4712 2.251 3.719 3.8413 3.719 1.2234 0 2.2021-.7585 2.2021-.7585z' />
      <path d='m19.0976 21.2794c2.0798 0 4.6733-.4159 5.7988-1.3212.2202-.2202.5872-.1713.8074.0489.1958.2202.1713.5872-.0734.783-1.1989 1.0031-3.6945 1.6148-6.5083 1.6148-2.8382 0-5.3094-.6361-6.5083-1.6148-.2447-.1958-.2691-.5383-.0734-.783.1957-.2446.5383-.2691.783-.0734 1.101.9298 3.6945 1.3457 5.7742 1.3457z' />
    </g>
  </svg>
)

export default SvgSidebarPinCrown
