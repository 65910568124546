import { v4 as uuid } from 'uuid'
import { uploadMedia, uploadTinymceImage } from '../../../../api/fileUploadApiService'
import { ObjectAny } from '../../../../types/commonTypes'
import { getRandomTempId } from '../../../../util/functional'

// NOTE: tinymce does not export much of the internal types, so some are ObjectAny and Function
export const imagesUploadHandler = (blobInfo: ObjectAny, progress: (percentage: number) => void) =>
  new Promise<string>((resolve, reject) => {
    progress(50)
    uploadTinymceImage({ fileName: blobInfo.filename(), inputFile: blobInfo.blob() }).then((result) => {
      if (result.success) {
        progress(100)
        resolve(result.value.url)
      } else {
        progress(100)
        console.error(result.error?.message ?? 'Unknown image upload error')
        reject('Failed to upload image. Please try again or contact us for support.')
      }
    })
  })

export const filePickerCallback = (cb: Function) => {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'audio/*,video/*')

  input.onchange = function () {
    const inputFile = input.files![0]
    const randomPart = uuid()
    const uniqueFileName = getMediaFileName(inputFile, randomPart.toString())
    uploadMedia({ fileName: uniqueFileName, inputFile })
      .then((result) => {
        if (result.success) {
          cb(result.value.url)
        } else {
          console.error(result.error?.message ?? 'Unknown file upload error')
          alert('Failed to upload media. Please try again or contact us for support.')
        }
      })
      .catch((e) => {
        console.error(e?.message ?? 'Unknown file upload error')
        alert('Failed to upload media. Please try again or contact us for support.')
      })
  }

  input.click()
}

window.seppoUploadDroppedFile = (file: File, cb: Function) => {
  const inputFile = file
  const randomPart = uuid()
  const uniqueFileName = getMediaFileName(inputFile, randomPart.toString())
  uploadMedia({ fileName: uniqueFileName, inputFile })
    .then((result) => {
      if (result.success) {
        cb(result.value.url)
      } else {
        console.error(result.error?.message ?? 'Unknown file upload error')
        alert('Failed to upload media. Please try again or contact us for support.')
      }
    })
    .catch((e) => {
      console.error(e?.message ?? 'Unknown file upload error')
      alert('Failed to upload media. Please try again or contact us for support.')
    })
}

const getRandomMediaId = () => `av_${getRandomTempId()}`

export const audioTemplateCallback = (data: ObjectAny) => {
  const id = getRandomMediaId()
  setTimeout(() => {
    const searchFor = "[src='" + data.source + "']"
    const el = window.tinymce.EditorManager.activeEditor.contentWindow.document.querySelectorAll(searchFor)[0]
      ?.parentNode as HTMLAudioElement
    if (el) el.load()
  }, 3000)
  if (data.source.startsWith(process.env.REACT_APP_S3_MEDIA_UPLOADED_PATH)) {
    return (
      `<audio id='${id}' controls="controls">\n` +
      `<source src='${getConvertedMediaUrl(data.source)}' type='audio/mp4'/>\n` +
      `<source src='${data.source}' type='audio/mpeg'/>\n` +
      '</audio>'
    )
  } else {
    return (
      `<audio id='${id}' controls="controls">\n` +
      `<source src="${data.source}"${data.sourcemime ? ` type="${data.sourcemime}"` : ''} />\n` +
      '</audio>'
    )
  }
}

export const videoTemplateCallback = (data: ObjectAny) => {
  const id = getRandomMediaId()
  if (data.source.startsWith(process.env.REACT_APP_S3_MEDIA_UPLOADED_PATH)) {
    return (
      `<video id='${id}' width="${data.width}" height="${data.height}" controls="controls">\n` +
      `<source src='${getConvertedMediaUrl(data.source)}' type='video/mp4'/>\n` +
      `<source src='${data.source}' type='video/mp4'/>\n` +
      '</video>'
    )
  } else {
    return (
      `<video id='${id}' width="${data.width}" height="${data.height}" controls="controls">\n` +
      `<source src="${data.source}"${data.sourcemime ? ` type="${data.sourcemime}"` : ''} />\n` +
      '</video>'
    )
  }
}

export const getConvertedMediaUrl = (mediaUrl: string): string => {
  const fileNameWithExt = mediaUrl.replace(process.env.REACT_APP_S3_MEDIA_UPLOADED_PATH!, '')
  const fileNameWithoutExt = fileNameWithExt.substring(0, fileNameWithExt.lastIndexOf('.')) || fileNameWithExt
  return `${process.env.REACT_APP_S3_MEDIA_CONVERTED_PATH}${fileNameWithoutExt}.mp4`
}

// NOTE: logic taken from smartfeet repo
const getMediaFileName = (file: File, randomSection: string): string => {
  const prefix = file.type.startsWith('video') ? '_trcyes_' : ''
  const normalized = file.name
    .replace(' ', '_')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '_')
    .replace(/[^A-Za-z0-9_.]/g, '')
    .replace('.m4a', '.mp4')
  return prefix + randomSection + normalized
}

export const getTinymceLanguage = (language?: string): string | undefined => {
  switch (language) {
    case 'de':
      return 'de'
    case 'es':
      return 'es'
    case 'fi':
      return 'fi'
    case 'it':
      return 'it'
    case 'nb':
      return 'nb_NO'
    case 'nl':
      return 'nl'
    case 'pt':
      return 'pt_BR'
    case 'sv':
      return 'sv_SE'
    default:
      return undefined
  }
}
