import classNames from 'classnames'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { IconName, getIcon } from '../../../common/components/icons/utils'
import { routes } from '../../../routes'
import styles from '../Sidebar.module.css'

export type SidebarSubLink = {
  to: string
  title: string
}

export type SidebarSubLinksProps = {
  title: string
  to: string
  icon: IconName
  subLinks: SidebarSubLink[]
}

export const SidebarSubLinks: React.FC<SidebarSubLinksProps> = ({ title, to, icon, subLinks }) => {
  const { pathname } = useLocation()

  const isAnySubLinkActive = [
    routes.generalSettings,
    routes.billingSettings,
    routes.organizationSettings,
    routes.membersSettings,
    routes.playersSettings,
    routes.licenseHolderSettings,
  ].includes(pathname)

  return (
    <li>
      <NavLink
        to={to}
        role='link'
        className={() => classNames(styles.sidebarLink, isAnySubLinkActive && styles.activeSidebarLink)}
      >
        {icon && <span className={styles.iconWrapper}>{getIcon(icon)}</span>}
        <span className={styles.linkTitle}>{title}</span>
      </NavLink>
      {isAnySubLinkActive && (
        <ul className={styles.subLinksList}>
          {subLinks.map((link) => (
            <li key={`sidebar_subLink:${link.title}`}>
              <NavLink
                to={link.to}
                className={({ isActive }) => classNames(styles.subLink, isActive && styles.activeSubLink)}
                role='link'
              >
                {link.title}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}
