import classNames from 'classnames'
import styles from './Banner.module.css'
import { PropsWithChildren } from 'react'

type BannerProps = {
  title?: string
  color?: 'primary' | 'secondary'
  hasCarousel?: boolean
}

export const BannerWithChildren: React.FC<PropsWithChildren<BannerProps>> = ({
  title,
  color = 'primary',
  hasCarousel = false,
  children,
}) => {
  return (
    <div
      className={classNames(
        hasCarousel ? styles.containerCarousel : color === 'primary' ? styles.container : styles.containerSecondary,
      )}
    >
      <div className={classNames(hasCarousel ? styles.carousel : styles.textAndActions)}>
        {!hasCarousel && <div className={styles.title}>{title}</div>}
        <div className={classNames(hasCarousel && styles.contentContainer)}>{children}</div>
      </div>
      {color === 'primary' && <div className={styles.firstCircle} />}
    </div>
  )
}
