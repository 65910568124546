import { useStorage } from '../../../hooks/useStorage'
import { HIDDEN_MESSAGES } from '../constants'
import { WelcomeBlockData, hideDistinctMessage, isMessageHidden } from '../helpers'
import { WelcomeBanner } from './WelcomeBanner'

type NewAccountWelcomeBannerProps = {
  data: WelcomeBlockData
  icon: JSX.Element
  language?: string
}

export const NewAccountWelcomeBanner: React.FC<NewAccountWelcomeBannerProps> = ({
  data: { id, ...rest },
  icon,
  language,
}) => {
  const { storedValue, storeValue } = useStorage<string[] | null>(HIDDEN_MESSAGES, null)

  const handleClickCloseMessage = () => {
    hideDistinctMessage(id, storedValue, storeValue)
  }

  if (storedValue != null && isMessageHidden(id, storedValue)) {
    return null
  }

  return (
    <WelcomeBanner
      data={rest}
      injectRawBody={rest.injectRawBody}
      onClose={handleClickCloseMessage}
      icon={icon}
      language={language}
    />
  )
}
