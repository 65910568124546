import React, { useCallback } from 'react'
import styles from './Engine.module.css'
import { EngineRunState, EngineRunStateItem, EngineRunStateOrder } from './EngineHelper'
import DoneIcon from '../../assets/images/engine/DoneIcon.svg'
import InProgressIcon from '../../assets/images/engine/InProgressIcon.svg'
import { t } from 'i18next'
import { ObjectAny } from '../../types/commonTypes'
import { Button } from '../../common/components/button/Button'
import RecentsIcon from '../../common/components/icons/RecentsIcon'

interface Props {
  gameName: string
  gameId: number
  gameMapUrl: string
  runState: Partial<EngineRunState> | null
}

const statusLabels: ObjectAny = {
  finalGameCreated: t('engine.status_label.final_game_publish', 'Publishing the second game'),
  storyLineCreated: t('engine.status_label.story_line_finalizing', 'Finalizing the storyline'),
  gameBoardAttached: t('engine.status_label.game_board_attaching', 'Attaching the gameboard'),
  allReady: t('engine.status_label.final_game_created', 'Second game published'),
}

export const GeneratedStep: React.FC<Props> = ({ gameName, gameId, runState, gameMapUrl }) => {
  const statusRowFor = useCallback(
    (key: EngineRunStateItem) => {
      if (!runState) return <div className={styles.statusIndicator} />

      return runState[key] ? (
        <>
          <img className={styles.statusIndicator} alt='' src={DoneIcon} />
          <div>{statusLabels[key]}</div>
          <div className={styles.statusShortLabel}>{t('engine.status_label.done', 'Done')}</div>
        </>
      ) : runState[EngineRunStateOrder[EngineRunStateOrder[key] - 1] as EngineRunStateItem] ||
        key === 'fileUploaded' ? (
        <>
          <img className={styles.statusIndicator} alt='' src={InProgressIcon} />
          <div>{statusLabels[key]}</div>
          <div className={styles.statusShortLabel}>{t('engine.status_label.processing', 'Processing')}</div>
        </>
      ) : (
        <>
          <div className={styles.statusIndicator} />
          <div>{statusLabels[key]}</div>
          <div className={styles.statusShortLabel}>{t('engine.status_label.todo', 'Todo')}</div>
        </>
      )
    },
    [runState],
  )

  const handleOpenGame = useCallback(() => {
    window.location.href = '/game-editor/' + gameId
  }, [gameId])

  const handleToDashboard = useCallback(() => {
    window.location.href = '/'
  }, [])

  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepTitle}>{t('engine.generated_step.game_generated', 'Game generated')}</div>
      <div className={styles.finalContent}>
        <div className={styles.gameCard} style={{ backgroundImage: 'url(' + gameMapUrl + ')' }}>
          <div className={styles.draftPill}>{'Draft'}</div>
          <div className={styles.gameCardBottom}>
            <div className={styles.gameName}>{gameName}</div>
            <div className={styles.gameInfo}>
              <RecentsIcon />
              <span>{t('engine.just_now', 'Just created')}</span>
            </div>
          </div>
        </div>
        {Object.keys(statusLabels).map((key) => {
          return (
            <>
              <div key={key} className={styles.statusRow}>
                {statusRowFor(key as EngineRunStateItem)}
              </div>
            </>
          )
        })}
      </div>
      <Button variant={'big'} disabled={!runState?.allReady} onClick={handleOpenGame}>
        {t('engine.generated_step.open_game', 'Open game')}
      </Button>
      <Button variant={'outline-normal'} disabled={!runState?.allReady} onClick={handleToDashboard}>
        {t('engine.generated_step.to_dashboard', 'Go to dashboard')}
      </Button>
    </div>
  )
}
