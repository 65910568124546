import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './AudioHandler.module.css'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { getIcon } from '../../../../../../common/components/icons/utils'
import { v4 as uuid } from 'uuid'
import { RoundButton } from '../../../../../../common/components/button/RoundButton'
import { uploadMedia } from '../../../../../../api/fileUploadApiService'
import { useNotification } from '../../../../../../contexts/NotificationContext'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Tooltip } from '../../../../../../common/components/tooltip/Tooltip'

type AudioHandlerProps = {
  disableAutoStart?: boolean
  onSubmitAudio: (audioUrl: string) => void
  onCancel: () => void
}

export const AudioHandler: React.FC<AudioHandlerProps> = ({ disableAutoStart = false, onSubmitAudio, onCancel }) => {
  const [audioBlob, setAudioBlob] = useState<Blob>()
  const { notifyError } = useNotification()
  const recorderControls = useAudioRecorder()
  const { t } = useTranslation()
  const recordingStarted = useRef(false)
  //Need isClosing to hide some things during closing delay, which is needed fot audiorecored to have time to unmount
  const isClosing = useRef(false)
  const [autoSent, setAutoSent] = useState(false)

  const handleSubmitAudioMessage = useCallback(async () => {
    const uniqueFileName = `${uuid()}.webm`
    if (audioBlob) {
      setAutoSent(false)
      const response = await uploadMedia({ fileName: uniqueFileName, inputFile: audioBlob })
      if (response.success) {
        setAudioBlob(undefined)
        onSubmitAudio(response.value.url)
      } else {
        notifyError({
          title: t('game_editor.chat.audio_message_upload_failed.title', 'Sending failed'),
          content: t('game_editor.chat.audio_message_upload_failed.content', 'Failed to upload audio file for sending'),
        })
      }
    }
  }, [audioBlob, notifyError, onSubmitAudio, t])

  useEffect(() => {
    if (!recordingStarted.current && !disableAutoStart) {
      recordingStarted.current = true
      recorderControls.startRecording()
    }
  }, [recorderControls, disableAutoStart])

  useEffect(() => {
    setAudioBlob(recorderControls.recordingBlob)
    if (autoSent) {
      handleSubmitAudioMessage()
    }
  }, [autoSent, handleSubmitAudioMessage, recorderControls.recordingBlob])

  const handleCloseAndCancelRecording = useCallback(() => {
    isClosing.current = true
    recorderControls.stopRecording()
    setTimeout(() => {
      setAudioBlob(undefined)
      recordingStarted.current = false
      isClosing.current = false
      onCancel()
    }, 250)
  }, [onCancel, recorderControls])

  const handleStartRecording = useCallback(() => {
    recorderControls.startRecording()
    let stopButton: HTMLElement | undefined
    function internalStopClick() {
      setAutoSent(true)
      setTimeout(() => {
        handleSubmitAudioMessage()
      }, 500)
    }
    setTimeout(() => {
      stopButton = document.querySelectorAll('[class^="AudioHandler_audioStopButton"]')[0] as HTMLElement
      if (stopButton) {
        stopButton.addEventListener('click', internalStopClick)
      }
    }, 100)

    return () => {
      if (stopButton) {
        stopButton.removeEventListener('click', internalStopClick)
      }
    }
  }, [handleSubmitAudioMessage, recorderControls])

  const handleTinySubmit = useCallback(() => {
    const saveButton = document.querySelectorAll('[class="tox-button"]')[1] as HTMLButtonElement
    window.recDiv.close()
    saveButton.click()
  }, [])

  return (
    <div
      className={classnames(
        styles.audioArea,
        !recorderControls.isRecording && styles.audioNotRecording,
        disableAutoStart && styles.narrowMode,
        audioBlob && styles.audioAreaPreview,
      )}
    >
      {!audioBlob && (
        <div className={styles.recorderAndInfoContainer}>
          {recorderControls.isRecording && (
            <div className={styles.recordingText}>{t('game_editor.chat.audio_recording', 'Recording')}</div>
          )}
          <div className={styles.recorder}>
            <AudioRecorder
              audioTrackConstraints={{
                noiseSuppression: true,
                echoCancellation: true,
              }}
              downloadFileExtension='webm'
              showVisualizer={true}
              recorderControls={recorderControls}
            />
            {recorderControls.isRecording && (
              <Tooltip tooltipContent={t('game_editor.chat.audio_recording_stop_review', 'Stop to review')}>
                {(tooltipProps) => (
                  <div onClick={recorderControls.stopRecording} className={styles.audioStopButton} {...tooltipProps} />
                )}
              </Tooltip>
            )}
            {!recorderControls.isRecording && disableAutoStart && (
              <Tooltip tooltipContent={t('game_editor.chat.audio_recording_start_recording', 'Start recording')}>
                {(tooltipProps) => (
                  <RoundButton
                    id='AUDIO_REC_START'
                    style={{ height: '2.5rem', display: 'none' }}
                    icon={'audio'}
                    variant='tiny'
                    onClick={handleStartRecording}
                  />
                )}
              </Tooltip>
            )}
          </div>
        </div>
      )}
      {!isClosing.current && !disableAutoStart && (
        <Tooltip tooltipContent={t('game_editor.chat.audio_recording_close_discard', 'Close to discard')}>
          {(tooltipProps) => (
            <div className={styles.closeAudioArea} onClick={handleCloseAndCancelRecording} {...tooltipProps}>
              {getIcon('close')}
            </div>
          )}
        </Tooltip>
      )}

      {audioBlob && !isClosing.current && !disableAutoStart && (
        <>
          <audio src={URL.createObjectURL(audioBlob)} controls />
          <RoundButton
            style={{ height: '2.5rem' }}
            icon={'arrowRight'}
            variant='tiny'
            onClick={handleSubmitAudioMessage}
          />
        </>
      )}
      {audioBlob && !isClosing.current && disableAutoStart && (
        <div className={styles.previewArea}>
          <audio src={URL.createObjectURL(audioBlob)} controls />
          <div className={styles.buttonsRow}>
            <button
              onClick={handleCloseAndCancelRecording}
              aria-label={t('common.cancel')}
              data-mce-name='Cancel'
              type='button'
              tabIndex={-1}
              data-alloy-tabstop='true'
              className='tox_-button tox-button--secondary'
            >
              {t('common.cancel')}
            </button>
            <button
              onClick={handleTinySubmit}
              aria-label={t('common.save')}
              data-mce-name='Save'
              type='button'
              tabIndex={-1}
              data-alloy-tabstop='true'
              className='tox-button'
            >
              {t('common.save')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
