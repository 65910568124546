import React, { useCallback, useState } from 'react'
import styles from './Engine.module.css'
import { SelectionOptions } from './EngineHelper'
import { t } from 'i18next'
import { Button } from '../../common/components/button/Button'

interface Props {
  selectionOptions: SelectionOptions
  onSelect: (selectedIndex: number) => void
}

export const ChooseStep: React.FC<Props> = ({ selectionOptions, onSelect }) => {
  const [preSelectIndex, setPreSelectIndex] = useState<number | null>(null)

  const internalSelect = useCallback(
    (index: number) => () => {
      setPreSelectIndex(index)
    },
    [],
  )

  const handleContinue = useCallback(() => {
    if (preSelectIndex !== null) onSelect(preSelectIndex)
  }, [onSelect, preSelectIndex])

  if (!selectionOptions) {
    return <div />
  }

  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepTitle}>{t('engine.customize.title', 'Customize your game')}</div>
      <div id='instructions_area' className={styles.instructionsArea}>
        <div style={{ display: 'flex', fontWeight: 'bold', alignItems: 'center', marginBottom: '16px' }}>
          <h3>{t('engine.customize.select_title', 'Select the game style you want to use')}</h3>
        </div>
        <div className={styles.instructionText}>
          {t(
            'engine.customize.intro',
            "Review the options below and choose your favourite. We'll generate and add the final game to your Seppo account based on your selection!",
          )}
        </div>
      </div>

      <div className={styles.selectionContainer}>
        {selectionOptions?.plot_lines.map((plotLine, index) => {
          return (
            <div key={index} className={styles.selectionRow}>
              <div
                className={styles.gameMap}
                style={{ backgroundImage: 'url(' + selectionOptions?.image_urls[index] + ')' }}
              >
                <Button
                  variant={preSelectIndex === index ? 'normal' : 'outline-normal'}
                  onClick={internalSelect(index)}
                >
                  {preSelectIndex === index
                    ? t('engine.customize.selected', 'Selected')
                    : t('engine.customize.choose_style', 'Choose style')}
                </Button>
              </div>
              <div className={styles.plotline}>{plotLine}</div>
            </div>
          )
        })}
      </div>
      <Button variant={'big'} disabled={preSelectIndex === null} onClick={handleContinue}>
        {t('engine.customize.continue', 'Continue')}
      </Button>
    </div>
  )
}
