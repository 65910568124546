import * as React from 'react'
import { SVGProps } from 'react'

const SvgSidebarPinDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' width='100%' height='100%' viewBox='3 4 32 32' xmlns='http://www.w3.org/2000/svg'>
    <filter id='a' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse' height='7' width='16' x='11' y='32'>
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feBlend in='SourceGraphic' in2='BackgroundImageFix' mode='normal' result='shape' />
      <feGaussianBlur result='effect1_foregroundBlur_3193_288' stdDeviation='1.5' />
    </filter>
    <filter
      id='b'
      color-interpolation-filters='sRGB'
      filterUnits='userSpaceOnUse'
      height='42.0298'
      width='36.77'
      x='.514186'
      y='.342791'
    >
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        result='hardAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
      />
      <feOffset dy='1.8286' />
      <feGaussianBlur stdDeviation='2.74291' />
      <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
      <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_3193_288' />
      <feBlend in='SourceGraphic' in2='effect1_dropShadow_3193_288' mode='normal' result='shape' />
    </filter>
    <linearGradient id='c' gradientUnits='userSpaceOnUse' x1='18.8992' x2='18.8992' y1='4' y2='35.0582'>
      <stop offset='0' stop-color='#fff' />
      <stop offset='1' stop-color='#fff' stop-opacity='0' />
    </linearGradient>
    <g filter='url(#b)'>
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='currentColor'
      />
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='url(#c)'
        fill-opacity='.25'
      />
    </g>
    <g fill='#fff'>
      <path d='m15.3454 14.9052c.0259.0516.0775.155.1292.155h7.029c.0516 0 .1033-.1034.1292-.155.0258-.0517.0258-.1293 0-.1809l-1.318-2.5584c-.0258-.0517-.0775-.0517-.155-.0517h-4.3414c-.0125 0-.0266-.0015-.0411-.003-.0452-.0049-.0944-.0103-.114.0289l-1.3179 2.5842c-.0258.0775-.0258.155 0 .1809z' />
      <path d='m25.1394 12.1401 1.8348 2.5325c.0258.0517.0258.1809.0258.2067-.0258.0517-.1034.1551-.1551.1551h-3.3852c-.0775 0-.1292-.0776-.1551-.1292l-1.3179-2.6359c-.0258-.0517-.0258-.0775 0-.1292.0192-.0383.0526-.034.0896-.0292.0129.0016.0263.0033.0396.0033h2.8943c.0133 0 .0267-.0017.0396-.0033.0371-.0048.0704-.0091.0896.0292z' />
      <path d='m23.4338 15.8355h3.3595c.0775 0 .1292.0258.155.1033.0258.0776.0258.1292-.0258.1809l-6.7447 7.5716c-.0259.0259-.0775.0517-.1292.0517-.0259 0-.0517 0-.0776-.0258-.0516-.0517-.0775-.1292-.0516-.2068l3.3594-7.5716c.0258-.0775.0775-.1033.155-.1033z' />
      <path d='m14.596 15.0602h-3.4111c-.0775 0-.1292-.0775-.1551-.1292-.0516-.0775-.0258-.1809 0-.2326l1.8348-2.5583c.0192-.0383.0525-.034.0896-.0292.0129.0016.0263.0033.0396.0033h2.946c.0516 0 .1033 0 .1292.0517.0258.0517.0258.0776 0 .1292l-1.318 2.6101c-.0258.0516-.1033.155-.155.155z' />
      <path d='m14.7252 15.913c-.0258-.0517-.0775-.1034-.155-.1034h-3.3595c-.0775 0-.1292.0259-.155.1034-.0259.0517-.0259.1292.0258.1809l6.7447 7.5716c.0258.0258.0775.0517.1292.0517.0259 0 .0517 0 .0775-.0259.0776-.0516.1034-.1292.0517-.2067z' />
      <path d='m22.5294 15.8355c.0517 0 .1034.0258.1292.0775.0259.0258.0517.0775.0259.155l-3.5145 7.9334c-.0259.0776-.0775.1034-.1551.1034-.0775 0-.1292-.0517-.155-.1034l-3.5145-7.9334c-.0258-.0516-.0258-.1033 0-.155.0259-.0517.0775-.0775.1292-.0775z' />
    </g>
  </svg>
)

export default SvgSidebarPinDiamond
