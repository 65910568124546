import React, { useCallback, useState } from 'react'
import styles from './Engine.module.css'
import { t } from 'i18next'
import { INSTRUCTIONS_DEFAULT } from './EngineHelper'

interface Props {
  initialOpen?: boolean
}

export const Instructions: React.FC<Props> = ({ initialOpen = true }) => {
  const [instructionsOpen, setInstructionsOpen] = useState(initialOpen)

  const toggleInstructions = useCallback(() => {
    setInstructionsOpen(!instructionsOpen)
  }, [instructionsOpen])

  return (
    <div id='instructions_area' className={styles.instructionsArea}>
      <div style={{ display: 'flex', fontWeight: 'bold', alignItems: 'center' }}>
        <div>{t('engine.instructions_title', 'Instructions')}</div>
        {instructionsOpen ? (
          <div onClick={toggleInstructions} className={styles.collapseToggler}>
            -
          </div>
        ) : (
          <div onClick={toggleInstructions} className={styles.collapseToggler}>
            +
          </div>
        )}
      </div>
      <div
        style={{ display: instructionsOpen ? '' : 'none' }}
        className={styles.instructionText}
        dangerouslySetInnerHTML={{ __html: t('engine.instructions_body', INSTRUCTIONS_DEFAULT) }}
      />
    </div>
  )
}
