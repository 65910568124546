import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { ExerciseType } from '../../../../../api/gameTypes'
import { EditorFormField } from '../../../../../common/components/Form/EditorFormField/EditorFormField'
import { FormSubmitError } from '../../../../../common/components/Form/FormFieldError/FormSubmitError'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../../common/components/PopupMenu/PopupActionMenu'
import { getIcon } from '../../../../../common/components/icons/utils'
import { Game, Subtask } from '../../../../../types/commonTypes'
import { getTotalPointsForSubtask } from '../../../../../util/game'
import { PointsPreview } from '../../Points/PointsPreview'
import { CheckboxSubtaskFields } from './SubtaskFields/CheckboxSubtask/CheckboxSubtaskFields'
import { CreativeSubtaskFields } from './SubtaskFields/CreativeSubtask/CreativeSubtaskFields'
import { MatchPairsSubtaskFields } from './SubtaskFields/MatchPairsSubtask/MatchPairsSubtaskFields'
import { MissingWordSubtaskFields } from './SubtaskFields/MissingWordSubtask/MissingWordSubtaskFields'
import { MultipleChoiceSubtaskFields } from './SubtaskFields/MultipleChoiceSubtask/MultipleChoiceSubtaskFields'
import styles from './TaskModal.module.css'
import { getDescriptionForTaskType, getIconNameForTaskType, getTitleForTaskType } from './helpers'
import { ExploreSubtaskFields } from './SubtaskFields/ExploreSubtask/ExploreSubtaskFields'

type SubtaskFieldProps = {
  value: Subtask
  name: string
  onDelete?: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  id: string
  game: Game
  forceExpandToken?: number
  hasAnyErrors?: boolean
  viewOnly: boolean
}

export const SubtaskField: React.FC<SubtaskFieldProps> = ({
  game,
  value,
  name,
  onDelete,
  onMoveUp,
  onMoveDown,
  id,
  hasAnyErrors,
  forceExpandToken,
  viewOnly,
}) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const toggleExpanded = () => setIsExpanded((prev) => !prev)
  const { meta: recordLevelMeta } = useField(`${name}.tempId`)

  const pointsSum = getTotalPointsForSubtask(value)

  useEffect(() => {
    if (forceExpandToken && hasAnyErrors) {
      setIsExpanded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceExpandToken])

  return (
    <div className={styles.subtaskContainer} id={id}>
      <div className={styles.subtaskHeader}>
        <div className={styles.subtaskHeaderTitle}>
          <span>{getIcon(getIconNameForTaskType(value.type))}</span>
          <span>{getTitleForTaskType(value.type, t, true)}</span>
        </div>
        <div className={styles.subtaskHeaderPointsAndActions}>
          {!game.advancedSettings.noPointsGame && value.type !== ExerciseType.ExploreExercise && (
            <PointsPreview points={pointsSum} sizeVariant='medium' />
          )}
          <div className={styles.subtaskHeaderActions}>
            <PopupActionMenu
              id={`${id}_actionsMenu`}
              position='inline-down'
              buttonClassName={styles.subtaskHeaderMoreButton}
            >
              <PopupActionMenuItem
                icon='arrowDown'
                text={isExpanded ? t('common.collapse', 'Collapse') : t('common.expand', 'Expand')}
                onClick={toggleExpanded}
              />
              {onMoveUp != null && (
                <PopupActionMenuItem icon='longerArrowUp' text={t('common.move_up', 'Move up')} onClick={onMoveUp} />
              )}
              {onMoveDown != null && (
                <PopupActionMenuItem
                  icon='longerArrowDown'
                  text={t('common.move_down', 'Move down')}
                  onClick={onMoveDown}
                />
              )}
              {onDelete != null && (
                <PopupActionMenuItem icon='trash' text={t('common.delete', 'Delete')} onClick={onDelete} />
              )}
            </PopupActionMenu>
          </div>
        </div>
      </div>
      <div className={classNames(styles.subtaskFieldsContainer, isExpanded && styles.subtaskFieldsContainer_visible)}>
        <h5>{t('game_editor.tasks.task_description', 'Task description')}</h5>
        <p className={styles.subtaskDescription}>{getDescriptionForTaskType(value.type, t)}</p>
        {value.type !== ExerciseType.MissingWordExercise && (
          <EditorFormField
            key={`${id}_editor`}
            name={`${name}.description`}
            label={t('game_editor.tasks.task_description_accessibility_label', 'Describe the task')}
            srOnlyLabel
            disabled={viewOnly}
          />
        )}
        <div className={classNames(styles.subtaskFieldsContainer, isExpanded && styles.subtaskFieldsContainer_visible)}>
          {value.type === ExerciseType.CreativeExercise && (
            <CreativeSubtaskFields
              name={name}
              value={value}
              noPoints={game.advancedSettings.noPointsGame}
              viewOnly={viewOnly}
            />
          )}
          {value.type === ExerciseType.PollExercise && (
            <CheckboxSubtaskFields
              name={name}
              value={value}
              noPoints={game.advancedSettings.noPointsGame}
              viewOnly={viewOnly}
            />
          )}
          {value.type === ExerciseType.MultichoiceExercise && (
            <MultipleChoiceSubtaskFields
              name={name}
              value={value}
              noPoints={game.advancedSettings.noPointsGame}
              viewOnly={viewOnly}
            />
          )}
          {value.type === ExerciseType.CombineExercise && (
            <MatchPairsSubtaskFields
              name={name}
              value={value}
              noPoints={game.advancedSettings.noPointsGame}
              viewOnly={viewOnly}
            />
          )}
          {value.type === ExerciseType.MissingWordExercise && (
            <MissingWordSubtaskFields
              name={name}
              value={value}
              noPoints={game.advancedSettings.noPointsGame}
              viewOnly={viewOnly}
            />
          )}
          {value.type === ExerciseType.ExploreExercise && (
            <ExploreSubtaskFields name={name} value={value} viewOnly={viewOnly} />
          )}
        </div>
      </div>
      {forceExpandToken && (
        <FormSubmitError
          error={recordLevelMeta.error}
          modifiedSinceSubmit={false}
          className={styles.subtaskEntityError}
        />
      )}
    </div>
  )
}
