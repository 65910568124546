import { FORM_ERROR } from 'final-form'
import { TFunction } from 'i18next'
import { ExerciseType } from '../../../../../api/gameTypes'
import { IconName } from '../../../../../common/components/icons/utils'
import {
  CheckboxAnswerOption,
  CheckboxSubtaskData,
  CreateAndCommentSubtaskData,
  CreativeAnswerOption,
  CreativeSubtaskData,
  DeepPartial,
  FormErrorType,
  Game,
  MatchPairsAnswerOption,
  MatchPairsSubtaskData,
  MissingWordAnswerOption,
  MissingWordSubtaskData,
  MultipleChoiceAnswerOption,
  MultipleChoiceSubtaskData,
  PointsType,
  Subtask,
  Task,
  TaskAdvanced,
} from '../../../../../types/commonTypes'
import { getRandomTempId } from '../../../../../util/functional'
import { getTotalPointsForTask } from '../../../../../util/game'
import { safeIsNullOrEmpty } from '../../../../../util/string'
import {
  aboveZeroValidation,
  lowerThanOrEqualToValidation,
  rangeValidation,
  requiredValidation,
  zeroOrAboveValidation,
} from '../../../../../util/validate'

export const getIconNameForTaskType = (type: ExerciseType): IconName => {
  switch (type) {
    case ExerciseType.CombineExercise:
      return 'taskMatchPairs'
    case ExerciseType.MissingWordExercise:
      return 'taskMissingWord'
    case ExerciseType.PollExercise:
      return 'circleCheckmark'
    case ExerciseType.MultichoiceExercise:
      return 'taskMultipleChoice'
    case ExerciseType.CollaborationExercise:
      return 'taskCreative'
    case ExerciseType.CreativeExercise:
      return 'taskCreative'
    case ExerciseType.ExploreExercise:
      return 'eye'
  }
}

export const getTitleForTask = (task: Task, t: TFunction, longer?: boolean): string => {
  return task.subtasks.length > 1
    ? t('tasks.multiple_tasks_title', 'Multiple')
    : getTitleForTaskType(task.type as ExerciseType, t, longer)
}

export const getTitleForTaskType = (type: ExerciseType, t: TFunction, longer?: boolean): string => {
  switch (type) {
    case ExerciseType.CombineExercise:
      return longer
        ? t('tasks.match_pairs_title_long', 'Match pairs task')
        : t('tasks.match_pairs_title_short', 'Match pairs')
    case ExerciseType.MissingWordExercise:
      return longer
        ? t('tasks.missing_word_title_long', 'Missing word task')
        : t('tasks.missing_word_title_short', 'Missing word')
    case ExerciseType.PollExercise:
      return longer ? t('tasks.checkbox_title_long', 'Checkbox task') : t('tasks.checkbox_title_short', 'Checkbox')
    case ExerciseType.MultichoiceExercise:
      return longer
        ? t('tasks.multiple_choice_title_long', 'Multiple choice task')
        : t('tasks.multiple_choice_title_short', 'Multiple choice')
    case ExerciseType.CollaborationExercise:
      return longer
        ? t('tasks.create_and_comment_title_long', 'Create and comment task')
        : t('tasks.create_and_comment_title_short', 'Create and comment')
    case ExerciseType.CreativeExercise:
      return longer ? t('tasks.creative_title_long', 'Creative task') : t('tasks.creative_title_short', 'Creative')
    case ExerciseType.ExploreExercise:
      return longer
        ? t('tasks.explore_title_long', 'Eplore - View only task')
        : t('tasks.Explore_title_short', 'Explore')
  }
}

export const getDescriptionForTaskType = (type: ExerciseType, t: TFunction): string => {
  switch (type) {
    case ExerciseType.CombineExercise:
      return t(
        'tasks.match_pairs_description',
        'In this task type you can match: words, words and pictures, or just pictures. Remember to add the alternative texts to the images.',
      )
    case ExerciseType.MissingWordExercise:
      return t(
        'tasks.missing_word_description',
        'Write a sentence, place the cursor where you want the player to fill the missing word and click Add missing word. ',
      )
    case ExerciseType.PollExercise:
      return t(
        'tasks.checkbox_description',
        'This task allows players to select multiple answers to a question. Answer are graded automatically based on the points and feedback you define.',
      )
    case ExerciseType.MultichoiceExercise:
      return t('tasks.multiple_choice_description', 'General description of the task type here (from system)')
    case ExerciseType.CollaborationExercise:
      return t('tasks.create_and_comment_description', 'General description of the task type here (from system)')
    case ExerciseType.CreativeExercise:
      return t(
        'tasks.creative_description',
        'Creative tasks allow players to create their own answers using the options you enable, from text to images and video to audio. Define the ask, toggle the answer options and let your players craft their responses.',
      )
    case ExerciseType.ExploreExercise:
      return t(
        'tasks.explore_description',
        'The Explore task allows players to engage with content such as videos, images, text, or links without needing to submit a response. This task type enables players to explore the provided material and continue their gameplay without the need to collect points.',
      )
  }
}

export const validateCreativeSubtaskData = (
  data: DeepPartial<CreativeSubtaskData> | undefined,
  t: TFunction,
  noPointsGame?: boolean,
): FormErrorType<CreativeSubtaskData> => {
  return {
    answerOptions: !data?.answerOptions?.length ? t('validation_errors.required', 'Required') : undefined,
    pointsForAnswer: !noPointsGame ? aboveZeroValidation(data?.pointsForAnswer, t) : undefined,
    autoScoreOption:
      !noPointsGame && data?.pointsType === PointsType.Automatic
        ? requiredValidation(data?.autoScoreOption, t)
        : undefined,
    autoScorePercentage:
      !noPointsGame && data?.pointsType === PointsType.Automatic && data?.autoScoreOption === 'other'
        ? aboveZeroValidation(data?.autoScorePercentage, t) ??
          lowerThanOrEqualToValidation(data?.autoScorePercentage, 100, t)
        : undefined,
    evaluationGuideline: data?.hasEvaluationGuideline ? requiredValidation(data?.evaluationGuideline, t) : undefined,
    automatedFeedback: data?.hasAutomatedFeedback ? requiredValidation(data?.automatedFeedback, t) : undefined,
  }
}

export const validateCheckboxAnswerOption = (
  answer: DeepPartial<CheckboxAnswerOption> | undefined,
  t: TFunction,
): FormErrorType<CheckboxAnswerOption> => {
  return {
    text:
      safeIsNullOrEmpty(answer?.text) && safeIsNullOrEmpty(answer?.imageUrl)
        ? t('game_editor.tasks.validation_errors.text_or_image_required', 'Required - add text and/or image')
        : undefined,
  }
}

export const validateCheckboxSubtaskData = (
  data: DeepPartial<CheckboxSubtaskData> | undefined,
  t: TFunction,
): FormErrorType<CheckboxSubtaskData> => {
  return {
    allAnswersPoints: data?.allAnswersHaveSamePoints ? aboveZeroValidation(data?.allAnswersPoints, t) : undefined,
    answers: data?.answers?.map((answer) => validateCheckboxAnswerOption(answer, t)),
    automatedFeedback: data?.hasAutomatedFeedback ? requiredValidation(data?.automatedFeedback, t) : undefined,
  }
}

const validateAtLeastOneAnswerOption = (t: TFunction, answers: any[] = [], isMissingWord?: boolean) => {
  return answers.length === 0
    ? isMissingWord
      ? t('game_editor.tasks.validation_errors.at_least_one_missing_word', 'Add at least one missing word')
      : t('game_editor.tasks.validation_errors.at_least_one_answer_option', 'Add at least one answer option')
    : undefined
}

const validateAtLeastSomePoints = (t: TFunction, answers?: Array<{ points?: number } | undefined>) => {
  return !answers?.some((answer) => (answer?.points ?? 0) > 0)
    ? t(
        'game_editor.tasks.validation_errors.at_least_one_answer_needs_points',
        'At least one answer must have points above 0',
      )
    : undefined
}

const validateAtLeastOneCorrect = (t: TFunction, answers?: Array<{ isCorrectAnswer?: boolean } | undefined>) => {
  return !answers?.some((answer) => answer?.isCorrectAnswer)
    ? t(
        'game_editor.tasks.validation_errors.at_least_one_answer_correct_validation',
        'At least one answer must be marked as correct',
      )
    : undefined
}

export const validateCreateAndCommentSubtaskData = (
  data: DeepPartial<CreateAndCommentSubtaskData> | undefined,
  t: TFunction,
): FormErrorType<CreateAndCommentSubtaskData> => {
  return {
    test: requiredValidation(data?.test, t),
  }
}

const validateTextOrImage = (t: TFunction, text?: string, imageUrl?: string) => {
  return safeIsNullOrEmpty(text) && safeIsNullOrEmpty(imageUrl)
    ? t('game_editor.tasks.validation_errors.text_or_image_required', 'Required - add text and/or image')
    : undefined
}

export const validateMatchPairsAnswerOption = (
  answer: DeepPartial<MatchPairsAnswerOption> | undefined,
  t: TFunction,
  noPointsGame?: boolean,
): FormErrorType<MatchPairsAnswerOption> => {
  return {
    left: {
      text: validateTextOrImage(t, answer?.left?.text, answer?.left?.imageUrl),
    },
    right: {
      text: validateTextOrImage(t, answer?.right?.text, answer?.right?.imageUrl),
    },
    points: noPointsGame ? undefined : zeroOrAboveValidation(answer?.points, t),
  }
}

export const validateMatchPairsSubtaskData = (
  data: DeepPartial<MatchPairsSubtaskData> | undefined,
  t: TFunction,
  noPointsGame?: boolean,
): FormErrorType<MatchPairsSubtaskData> => {
  return {
    allAnswersPoints: data?.allAnswersHaveSamePoints ? aboveZeroValidation(data?.allAnswersPoints, t) : undefined,
    answers: data?.answers?.map((answer) => validateMatchPairsAnswerOption(answer, t, noPointsGame)),
  }
}

export const validateMissingWordAnswerOption = (
  data: DeepPartial<MissingWordSubtaskData> | undefined,
  answer: DeepPartial<MissingWordAnswerOption> | undefined,
  t: TFunction,
  noPointsGame?: boolean,
): FormErrorType<MissingWordAnswerOption> => {
  return {
    word: requiredValidation(answer?.word, t),
    points: !noPointsGame && !data?.allAnswersHaveSamePoints ? aboveZeroValidation(answer?.points, t) : undefined,
  }
}

export const validateMissingWordSubtaskData = (
  data: DeepPartial<MultipleChoiceSubtaskData> | undefined,
  t: TFunction,
  noPointsGame?: boolean,
): FormErrorType<MissingWordSubtaskData> => {
  return {
    allAnswersPoints: data?.allAnswersHaveSamePoints ? aboveZeroValidation(data.allAnswersPoints, t) : undefined,
    answers: data?.answers?.map((answer) => validateMissingWordAnswerOption(data, answer, t, noPointsGame)),
  }
}

export const validateMultipleChoiceAnswerOption = (
  answer: DeepPartial<MultipleChoiceAnswerOption> | undefined,
  t: TFunction,
): FormErrorType<MultipleChoiceAnswerOption> => {
  return {
    text: validateTextOrImage(t, answer?.text, answer?.imageUrl),
    feedback: answer?.hasFeedback ? requiredValidation(answer.feedback, t) : undefined,
  }
}

export const validateMultipleChoiceSubtaskData = (
  data: DeepPartial<MultipleChoiceSubtaskData> | undefined,
  t: TFunction,
): FormErrorType<MultipleChoiceSubtaskData> => {
  return {
    allAnswersPoints: data?.allAnswersHaveSamePoints ? aboveZeroValidation(data?.allAnswersPoints, t) : undefined,
    answers: data?.answers?.map((answer) => validateMultipleChoiceAnswerOption(answer, t)),
  }
}

export const validateSubtask = (
  subtask: DeepPartial<Subtask> | undefined,
  game: Game,
  t: TFunction,
): FormErrorType<Subtask> => {
  const description = requiredValidation(subtask?.description, t)
  if (subtask?.type == null) {
    return {
      description,
    }
  }
  switch (subtask.type) {
    case ExerciseType.CollaborationExercise:
      return {
        description,
        data: validateCreateAndCommentSubtaskData(subtask.data, t),
      }
    case ExerciseType.CombineExercise:
      return {
        description,
        tempId:
          validateAtLeastOneAnswerOption(t, subtask.data?.answers) ??
          (!game.advancedSettings.noPointsGame ? validateAtLeastSomePoints(t, subtask?.data?.answers) : undefined),
        data: validateMatchPairsSubtaskData(subtask?.data, t, game.advancedSettings.noPointsGame),
      }
    case ExerciseType.CreativeExercise:
      return {
        description,
        data: validateCreativeSubtaskData(subtask.data, t, game.advancedSettings.noPointsGame),
      }
    case ExerciseType.MissingWordExercise:
      return {
        description,
        tempId: validateAtLeastOneAnswerOption(t, subtask?.data?.answers, true),
        data: validateMissingWordSubtaskData(subtask?.data, t, game.advancedSettings.noPointsGame),
      }
    case ExerciseType.MultichoiceExercise:
      return {
        description,
        tempId:
          validateAtLeastOneAnswerOption(t, subtask.data?.answers) ??
          (game.advancedSettings.noPointsGame
            ? validateAtLeastOneCorrect(t, subtask?.data?.answers)
            : validateAtLeastSomePoints(t, subtask?.data?.answers)),
        data: validateMultipleChoiceSubtaskData(subtask?.data, t),
      }
    case ExerciseType.PollExercise:
      return {
        description,
        tempId:
          validateAtLeastOneAnswerOption(t, subtask.data?.answers) ??
          (game.advancedSettings.noPointsGame
            ? validateAtLeastOneCorrect(t, subtask?.data?.answers)
            : validateAtLeastSomePoints(t, subtask?.data?.answers)),
        data: validateCheckboxSubtaskData(subtask?.data, t),
      }
    case ExerciseType.ExploreExercise:
      return {
        description,
      }
  }
}

const validateAdvanced = (
  advanced: DeepPartial<TaskAdvanced>,
  pointsSum: number,
  t: TFunction,
): FormErrorType<TaskAdvanced> => {
  return {
    requiredProximity: advanced.hasProximityLock ? requiredValidation(advanced.requiredProximity, t) : undefined,
    timeLimitSeconds: advanced.hasTimeLimit
      ? requiredValidation(advanced.timeLimitSeconds, t) ??
        (advanced.timeLimitSeconds! < 10
          ? t('game_editor.add_time.at_least_10_seconds_validation', 'Add at least 10 seconds of additional time')
          : undefined)
      : undefined,
    lockCode: advanced.hasLockCode ? requiredValidation(advanced.lockCode, t) : undefined,
    iconType: advanced.hasCustomIconType ? requiredValidation(advanced.iconType, t) : undefined,
    automaticBadgeId:
      advanced.hasAutomaticBadge && advanced.automaticBadgeId == null
        ? t('game_editor.tasks.validation_errors.select_badge', 'Choose a badge to award')
        : undefined,
    automaticBadgePoints:
      advanced.hasAutomaticBadge && advanced.automaticBadgePoints != null
        ? rangeValidation(advanced.automaticBadgePoints, 0, pointsSum, t)
        : undefined,
  }
}

export const validateTask = (values: DeepPartial<Task>, game: Game, t: TFunction): FormErrorType<Task> => {
  return {
    name: safeIsNullOrEmpty(values.name) ? t('validation_errors.required', 'Required') : undefined,
    [FORM_ERROR]:
      values.subtasks?.length == null || values.subtasks.length === 0
        ? t('game_editor.tasks.validation_errors.at_least_one_subtask', 'Add at least one task')
        : undefined,
    subtasks: values?.subtasks?.map((subtask) => validateSubtask(subtask, game, t)),
    ...(values.advanced != null && {
      advanced: validateAdvanced(values.advanced, getTotalPointsForTask(values as Task) ?? 0, t),
    }),
  }
}

export const getInitialValuesForTaskType = (type: ExerciseType): DeepPartial<Subtask> => {
  switch (type) {
    case ExerciseType.CollaborationExercise:
      return {
        type: ExerciseType.CollaborationExercise,
        tempId: getRandomTempId(),
        data: {
          test: 'CollaborationExercise',
        },
      }
    case ExerciseType.CombineExercise:
      return {
        type: ExerciseType.CombineExercise,
        tempId: getRandomTempId(),
        data: {
          allAnswersHaveSamePoints: false,
          answers: [{}, {}],
        },
      }
    case ExerciseType.CreativeExercise:
      return {
        type: ExerciseType.CreativeExercise,
        tempId: getRandomTempId(),
        data: {
          pointsType: PointsType.Evaluate,
          answerOptions: [CreativeAnswerOption.Text],
        },
      }
    case ExerciseType.MissingWordExercise:
      return {
        type: ExerciseType.MissingWordExercise,
        tempId: getRandomTempId(),
        data: {
          allAnswersHaveSamePoints: false,
        },
      }
    case ExerciseType.MultichoiceExercise:
      return {
        type: ExerciseType.MultichoiceExercise,
        tempId: getRandomTempId(),
        data: {
          allAnswersHaveSamePoints: false,
          answers: [{}, {}],
        },
      }
    case ExerciseType.PollExercise:
      return {
        type: ExerciseType.PollExercise,
        tempId: getRandomTempId(),
        data: {
          allAnswersHaveSamePoints: false,
          answers: [{}, {}],
        },
      }
    case ExerciseType.ExploreExercise:
      return {
        type: ExerciseType.ExploreExercise,
        tempId: getRandomTempId(),
        data: {
          ackRequired: false,
        },
      }
  }
}
