import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { activateSponsoredGame, getSponsoredLibraryGames, PAGE_SIZE } from '../api/gameApiService'
import { LibrarySource } from '../api/gameTypes'
import { parseLibraryGamesResponseToGameCards } from '../api/typeConverters'
import { PaginationSelectOption } from '../composites/GamesOverview/components/PaginationControls'
import { useGameNotifications } from '../hooks/useGameNotifications'
import { TGameCard } from '../types/commonTypes'
import { areObjectsEqual, noop } from '../util/functional'
import {
  GameDataProviderInterface,
  GameFilters,
  GameSortBy,
  useGameData as useOwnGameData,
} from './OwnGamesContextProvider'

const activateGamePlaceholder = () => null

interface Props {
  top10Mode?: boolean
  children?: React.ReactNode
}

// TODO: Add last
export const SponsoredGamesDataContext = createContext<GameDataProviderInterface>({
  currentPage: 1,
  pageSize: PAGE_SIZE,
  pageData: undefined,
  loading: true,
  lastPage: 1,
  allPageOptions: [] as PaginationSelectOption[],
  selectPageSize: noop,
  prev: noop,
  next: noop,
  setPage: noop,
  activateGame: activateGamePlaceholder,
  refreshGames: noop,
  sortBy: GameSortBy.UPDATED_AT_DESC,
  setSortBy: noop,
  copyGame: noop,
  deleteGame: noop,
  importGame: noop,
})

export const SponsoredGamesDataProvider: React.FC<Props> = ({ top10Mode = false, children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageData, setPageData] = useState<TGameCard[]>()
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)
  const [loading, setLoading] = useState(true)
  const [lastPage, setLastPage] = useState<number>(1)
  const [sortBy, setSortBy] = useState<GameSortBy>(GameSortBy.UPDATED_AT_DESC)
  const [allPageOptions, setAllPageOptions] = useState<PaginationSelectOption[]>([{ value: 1, label: '1' }])
  const [filtersState, setFiltersState] = useState<GameFilters>({ activationCode: '' } as GameFilters)
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false)
  const ownGameData = useOwnGameData()

  const { notifyFetchingGamesFailed } = useGameNotifications()

  const next = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState + 1)
  }, [loading])

  const prev = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState - 1)
  }, [loading])

  const selectPageSize = useCallback(
    (newPageSize: number) => {
      if (loading) {
        return
      }

      setCurrentPage(1)
      setPageSize(newPageSize)
    },
    [loading],
  )

  const setPage = useCallback(
    (newPage: number) => {
      if (loading) {
        return
      }

      setCurrentPage(newPage)
    },
    [loading],
  )

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    getSponsoredLibraryGames({
      perPage: pageSize,
      page: currentPage,
      filters: filtersState,
      orderBy: sortBy,
      top10Mode: top10Mode,
    })
      .then((SponsoredGamesResponse) => {
        if (SponsoredGamesResponse.success) {
          setLastPage(top10Mode ? 1 : Math.ceil(SponsoredGamesResponse.value.count / pageSize))
          setPageData(
            parseLibraryGamesResponseToGameCards(SponsoredGamesResponse.value, LibrarySource.SPONSORED).slice(
              0,
              top10Mode ? 10 : 500,
            ),
          )
        } else {
          notifyFetchingGamesFailed()
          console.error(SponsoredGamesResponse.error)
        }
      })
      .finally(() => {
        setLoading(false)
        setInitialFetchDone(true)
      })
    return () => abortController.abort()
  }, [currentPage, pageSize, notifyFetchingGamesFailed, filtersState, initialFetchDone, sortBy, top10Mode])

  useEffect(() => {
    const calculateAllPageOptions = Array.from({ length: lastPage }, (_, i) => ({
      value: i + 1,
      label: `${i + 1}`,
    }))
    setAllPageOptions(calculateAllPageOptions)
  }, [lastPage])

  const refreshGames = useCallback(() => {
    getSponsoredLibraryGames({
      perPage: pageSize,
      page: currentPage,
      filters: filtersState,
      orderBy: sortBy,
      top10Mode: top10Mode,
    }).then((SponsoredGamesResponse) => {
      if (SponsoredGamesResponse.success) {
        setPageData(
          parseLibraryGamesResponseToGameCards(SponsoredGamesResponse.value, LibrarySource.ORG).slice(
            0,
            top10Mode ? 10 : 500,
          ),
        )
        setLastPage(top10Mode ? 1 : Math.ceil(SponsoredGamesResponse.value.count / pageSize))
      } else {
        console.error(SponsoredGamesResponse.error)
      }
    })
  }, [pageSize, currentPage, filtersState, sortBy, top10Mode])

  const activateGame = useCallback(
    async (email?: string, organization?: string, sponsoredCode?: string, gameId?: string) => {
      const schoolName = organization
      const response = await activateSponsoredGame({ email, schoolName, sponsoredCode, gameId })
      if (response.success) {
        ownGameData.refreshGames()
      } else {
        console.error(response.error)
      }
      return response
    },
    [ownGameData],
  )

  const setFilters = useCallback((newFilters: GameFilters | Partial<GameFilters>, isPartial?: boolean) => {
    setFiltersState((prev) => {
      const filters = isPartial ? { ...prev, ...newFilters } : (newFilters as GameFilters)
      const unChanged = areObjectsEqual(filters, prev)
      if (!unChanged) {
        setCurrentPage(1)
      }
      return unChanged ? prev : filters
    })
  }, [])

  return (
    <SponsoredGamesDataContext.Provider
      value={{
        currentPage,
        pageSize,
        pageData,
        lastPage,
        sortBy,
        allPageOptions,
        loading,
        initialFetchDone,
        filters: filtersState,
        setPage,
        selectPageSize,
        next,
        prev,
        activateGame,
        refreshGames,
        setFilters,
        setSortBy,
        copyGame: noop,
        deleteGame: noop,
        importGame: noop,
      }}
    >
      {children}
    </SponsoredGamesDataContext.Provider>
  )
}

export const useGameData = () => {
  const context = useContext(SponsoredGamesDataContext)

  return context
}
