import * as React from 'react'
import { SVGProps } from 'react'

const SvgSidebarPinThreeStars = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' width='100%' height='100%' viewBox='3 4 32 32' xmlns='http://www.w3.org/2000/svg'>
    <filter id='a' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse' height='7' width='16' x='11' y='32'>
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feBlend in='SourceGraphic' in2='BackgroundImageFix' mode='normal' result='shape' />
      <feGaussianBlur result='effect1_foregroundBlur_3193_279' stdDeviation='1.5' />
    </filter>
    <filter
      id='b'
      color-interpolation-filters='sRGB'
      filterUnits='userSpaceOnUse'
      height='42.0298'
      width='36.77'
      x='.514186'
      y='.342791'
    >
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        result='hardAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
      />
      <feOffset dy='1.8286' />
      <feGaussianBlur stdDeviation='2.74291' />
      <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
      <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_3193_279' />
      <feBlend in='SourceGraphic' in2='effect1_dropShadow_3193_279' mode='normal' result='shape' />
    </filter>
    <linearGradient id='c' gradientUnits='userSpaceOnUse' x1='18.8992' x2='18.8992' y1='4' y2='35.0582'>
      <stop offset='0' stop-color='#fff' />
      <stop offset='1' stop-color='#fff' stop-opacity='0' />
    </linearGradient>
    <g filter='url(#b)'>
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='currentColor'
      />
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='url(#c)'
        fill-opacity='.25'
      />
    </g>
    <g fill='#fff'>
      <path d='m21.0993 15.9433c.024.024.0481.024.0721.024.0241 0 .0481-.024 0-.0481.0481-.0481.0722-.0962.0722-.1683l-.3848-2.3084 1.6832-1.6351c.0481-.0481.0722-.1203.0481-.1684 0-.0721-.0721-.1202-.1202-.1202l-2.3084-.3366-1.034-2.09204c-.0481-.12023-.2404-.12023-.2885 0l-1.034 2.09204-2.3084.3366c-.0481.024-.0962.0721-.1203.1202 0 .0722 0 .1203.0481.1684l1.6833 1.6351-.3848 2.3084c0 .0721.0241.1443.0722.1683.048.0481.1202.0481.1683.0241l2.0679-1.0821z' />
      <path d='m16.7951 22.5078c-.0241 0-.0481 0-.0722-.024l-2.0679-1.0821-2.068 1.0821c-.0481.024-.1202.024-.1683-.0241-.0481-.024-.0721-.0962-.0721-.1683l.3847-2.3084-1.6832-1.6352c-.0481-.048-.0481-.0961-.0481-.1683.024-.0481.0721-.0962.1202-.1202l2.3084-.3366 1.034-2.092c.0481-.1203.2405-.1203.2886 0l1.0339 2.092 2.3085.3366c.0721 0 .1202.0481.1202.1202.024.0481 0 .1203-.0481.1683l-1.6832 1.6352.3847 2.3084c0 .0721-.024.1202-.0721.1683.0721.0241.0481.0481 0 .0481z' />
      <path d='m25.5237 22.5078c-.024 0-.048 0-.0721-.024l-2.0679-1.0821-2.068 1.0821c-.0481.024-.1202.024-.1683-.0241-.0481-.024-.0722-.0962-.0722-.1683l.3848-2.3084-1.6832-1.6351c-.0481-.0481-.0481-.0962-.0481-.1684.024-.0481.0721-.0961.1202-.1202l2.3084-.3366 1.034-2.092c.0481-.1203.2404-.1203.2885 0l1.034 2.092 2.3565.3607c.0722 0 .1202.048.1202.1202.0241.0481 0 .1202-.048.1683l-1.6833 1.6351.3848 2.3084c0 .0722-.0241.1203-.0722.1684.0241 0-.024.024-.0481.024z' />
    </g>
  </svg>
)

export default SvgSidebarPinThreeStars
