import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { getWordPressData } from '../api/gameApiService'
import { WordPressData } from '../types/commonTypes'
import { parseWordPressResponseToWPData } from '../api/typeConverters'
import { noop } from '../util/functional'

export interface WordPressProviderInterface {
  wpData: WordPressData | null
  refreshWPData: () => void
  loading: boolean
}

export const WordPressDataContext = createContext<WordPressProviderInterface>({
  wpData: null,
  refreshWPData: noop,
  loading: true,
})

export const WordPressDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [wpData, setWpData] = useState<WordPressData | null>(null)
  const [loading, setLoading] = useState(true)
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false)

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    getWordPressData()
      .then((WordPressDataResponse) => {
        if (WordPressDataResponse.success) {
          setWpData(parseWordPressResponseToWPData(WordPressDataResponse.value))
        } else {
          console.error(WordPressDataResponse.error)
        }
      })
      .finally(() => {
        setLoading(false)
        setInitialFetchDone(true)
      })
    return () => abortController.abort()
  }, [initialFetchDone])

  const refreshWPData = useCallback(() => {
    const abortController = new AbortController()
    setLoading(true)
    getWordPressData()
      .then((WordPressDataResponse) => {
        if (WordPressDataResponse.success) {
          setWpData(parseWordPressResponseToWPData(WordPressDataResponse.value))
        } else {
          console.error(WordPressDataResponse.error)
        }
      })
      .finally(() => {
        setLoading(false)
      })
    return () => abortController.abort()
  }, [])

  return (
    <WordPressDataContext.Provider
      value={{
        wpData,
        refreshWPData,
        loading,
      }}
    >
      {children}
    </WordPressDataContext.Provider>
  )
}

export const useWordPressData = () => {
  const context = useContext(WordPressDataContext)

  return context
}
