import classNames from 'classnames'
import { useField } from 'react-final-form'
import { FormFieldProps } from '../../../../types/commonTypes'
import { InputField, InputFieldProps } from '../../input/InputField'
import { ERROR_OUTLINE_CLASS_NAME, FormFieldWithError } from '../FormFieldError/FormFieldWithError'
import styles from '../FormFields.module.css'

export const InputFormField: React.FC<FormFieldProps<Partial<InputFieldProps>>> = ({
  name,
  label,
  srOnlyLabel,
  id,
  fieldContainerClassName,
  inline,
  validateOnlyIfDirty,
  type = 'text',
  className,
  labelClassName,
  errorClassName,
  format,
  parse,
  alwaysShowErrors = false,
  ...rest
}) => {
  const { input } = useField(name, { type, format, parse })

  return (
    <FormFieldWithError
      name={name}
      validateOnlyIfDirty={validateOnlyIfDirty}
      wrapperClassName={classNames(inline && styles.inlineField, fieldContainerClassName)}
      errorClassName={errorClassName}
      alwaysShowErrors={alwaysShowErrors}
    >
      <label
        htmlFor={id ?? name}
        className={classNames(
          srOnlyLabel && 'sr-only',
          styles.label,
          !inline && styles.labelWithMargin,
          labelClassName,
        )}
      >
        {label}
      </label>
      <InputField
        {...rest}
        {...input}
        id={id ?? name}
        type={type}
        className={classNames(ERROR_OUTLINE_CLASS_NAME, className)}
      />
    </FormFieldWithError>
  )
}
