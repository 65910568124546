export const routes = {
  login: '/login',
  trialLogin: '/trial-login',
  homepage: '/',
  reports: '/reports',
  orgLibrary: '/org-library',
  community: '/community',
  sponsored: '/sponsored',
  sponsoredTop10: '/sponsoredTop10',
  templates: '/templates',
  gamePackages: '/game-packages/',
  gamePackageWithId: '/game-packages/:packageId',
  createGamePackageWithId: (id: number) => `/game-packages/${id}`,
  learn: '/learn',
  search: '/search',
  gameEditor: '/game-editor',
  gameEditorWithId: '/game-editor/:gameId',
  createGameEditorWithId: (id: string, legacyEditor: boolean, isNewGame?: boolean) =>
    isNewGame
      ? `/game-editor/${id}?is_new=true&legacy=${legacyEditor}`
      : `/game-editor/${id}${legacyEditor ? '?legacy=true' : ''}`,
  onboardingWizard: '/wizard',
  generalSettings: '/settings-general',
  billingSettings: '/settings-billing',
  organizationSettings: '/settings-organization',
  membersSettings: '/settings-organization/members',
  playersSettings: '/settings-organization/players',
  licenseHolderSettings: '/settings-organization/license-holders',
  passwordChange: '/password-change/',
  passwordChangeWithToken: '/password-change/:token',
  webshopSponsoredGames: '/shop/games',
  shareLoginInfo: '/share-game',
  engine: '/engine',
}

export const externalRoutes = {
  website: 'https://seppo.io/',
  webshop: 'https://play.seppo.io/newshop/shop',
}

const getOldUIBridgedUrl = (target: string, hideNavbar = true) => {
  const sessionKey = localStorage.getItem('sessionKey') as string
  const hideNavbarTarget = hideNavbar
    ? target.includes('?')
      ? `${target}&hide_navbar`
      : `${target}?hide_navbar`
    : target
  const encodedTarget = encodeURIComponent(hideNavbarTarget)
  return `${process.env.REACT_APP_OLD_UI_BASE_URL}/bridge_me?user_session=${sessionKey}&target=${encodedTarget}`
}

export const getOldUIRoutes = {
  gameEditor: (gameId: string, isNewGame?: boolean, hideNavbar?: boolean) =>
    getOldUIBridgedUrl(isNewGame ? `/game?is_new=true&game_id=${gameId}` : `/game?game_id=${gameId}`, hideNavbar),
  playersView: () => getOldUIBridgedUrl('/admin/instructor_classes'),
  businessReporting: (route: string) => getOldUIBridgedUrl(route),
  businessGroupReporting: () => getOldUIBridgedUrl('/business_group_reporting'),
  playerReporting: () => getOldUIBridgedUrl('/player_reporting'),
  sponsoredGamesReporting: () => getOldUIBridgedUrl('/sponsored_games_reporting'),
  teacherReporting: () => getOldUIBridgedUrl('/teacher_reporting'),
  oldDashboard: () => getOldUIBridgedUrl('/dashboard', false),
  manageLicence: () => getOldUIBridgedUrl('/newshop/shop ', false),
  gameReport: (gameId: number | null) => getOldUIBridgedUrl(`/admin/games/${gameId}/contents`),
  publicGameSummary: (gameId: number, secret: string, language: string) =>
    `${process.env.REACT_APP_OLD_UI_BASE_URL}/summary/${gameId}/${secret}?l=${language}`,
  playerAccountPasswordReset: (language = 'en') => {
    return `${process.env.REACT_APP_OLD_UI_BASE_URL}/gamer_accounts/password_reset?l=${language}`
  },
  scormPackage: (gameId: number, sendAnswersNormally: boolean) =>
    getOldUIBridgedUrl(`/admin/games/${gameId}/get_offline_package?send_answers_normally=${sendAnswersNormally}`),
}
