import ReactPannellum from 'react-pannellum'
import { useGame } from '../../../../contexts/GameContext'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { refreshDraggableMarkersToMap } from '../Marker/PanoramaDraggableMarkerHelper'
import { refreshDraggableDoorsToMap } from '../Door/PanoramaDraggableDoorHelper'
import { GameBoard } from '../../types'
import { Tooltipper } from './BoardsOverview/Tooltipper'
import { getDoorIdClass } from '../Door/DoorHelper'
import { DoorPopup } from '../Door/DoorPopup'
import { TOOLTIP_EXPLICIT_CLOSE_CLASS_ID } from '../../../../common/components/tooltip/Tooltip'

export const PANORAMA_DEFAULTS = {
  autoLoad: true,
  autoRotate: 0,
  showFullscreenCtrl: false,
}

type PanoramaBoardProps = {
  boardUrl: string
  activeBoardIndex?: number
  currentBoard: GameBoard
}

export const PanoramaBoard: React.FC<PanoramaBoardProps> = ({ boardUrl, activeBoardIndex = 0, currentBoard }) => {
  const { tasks, gameData, moveTask, updateExplorationDoor } = useGame()
  const [hoveringDoorId, setHoveringDoorId] = useState<number | null>(null)
  const [mapReady, setMapReady] = useState(false)
  const mapOptions = currentBoard.mapOptions

  useEffect(() => {
    setMapReady(false)
  }, [boardUrl])

  const handleMapReady = () => {
    setMapReady(true)
  }

  const filteredTasks = useMemo(() => {
    return tasks.filter(
      (t) =>
        !(
          t.mapIndex !== activeBoardIndex ||
          t.advanced.isFlash ||
          (gameData?.advancedSettings.levelsEnabled && t.level === 0)
        ),
    )
  }, [tasks, gameData?.advancedSettings.levelsEnabled, activeBoardIndex])

  const doors = useMemo(() => {
    return currentBoard.doors
  }, [currentBoard.doors])

  const setDoorActive = useCallback((data: any) => {
    setHoveringDoorId(data.detail)
  }, [])

  useEffect(() => {
    window.addEventListener('seppo.door_hover', setDoorActive)
    if (mapReady) {
      setTimeout(() => {
        refreshDraggableMarkersToMap(filteredTasks, moveTask)

        refreshDraggableDoorsToMap(currentBoard, doors ?? [], updateExplorationDoor)

        const mainElement = document.getElementsByClassName('pnlm-container')[0] as any
        mainElement.ondrop = (e: any) => {
          e.preventDefault()
        }
        mainElement.ondragover = (e: any) => {
          e.preventDefault()
        }
        mainElement.onmousemove = (e: any) => {
          if (e.target.classList.value.includes('pnlm-dragfix')) {
            setHoveringDoorId(null)
          }
          const elements = document.getElementsByClassName(TOOLTIP_EXPLICIT_CLOSE_CLASS_ID)
          if (elements.length && e.target.classList.value.includes('pnlm-dragfix')) {
            for (let index = 0; index < elements.length; index++) {
              const element = elements.item(index) as HTMLElement
              element?.click()
            }
          }
        }
      }, 50)
    }

    return () => {
      window.removeEventListener('seppo.door_hover', setDoorActive)
    }
  }, [filteredTasks, moveTask, mapReady, currentBoard, doors, updateExplorationDoor, setDoorActive])

  return (
    <div>
      <ReactPannellum
        id={'map_' + boardUrl}
        style={{ width: '100vw', height: 'calc(100vh - 5.25rem - 48px)', background: '#ffffff' }}
        sceneId='seppoScene'
        imageSource={'https://seppo-proxy.herokuapp.com/' + boardUrl}
        config={{
          ...PANORAMA_DEFAULTS,
          yaw: mapOptions ? mapOptions.center[0] : 0,
          pitch: mapOptions ? mapOptions?.center[1] : 0,
          zoom: mapOptions ? mapOptions?.zoom : 100,
        }}
        onPanoramaLoaded={handleMapReady}
      />
      {doors?.map((door) => {
        return (
          <Tooltipper
            key={door.id}
            classIdentifier={getDoorIdClass(door.id)}
            large
            center
            sticky
            ignoreEvents
            autoActivate={hoveringDoorId === door.id || door.leadsToBoardIndex === -1}
            tooltipElement={<DoorPopup door={door} />}
          />
        )
      })}
    </div>
  )
}
