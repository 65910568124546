import * as React from 'react'
import { SVGProps } from 'react'

const SvgSidebarPinTwoStars = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' width='100%' height='100%' viewBox='3 4 32 32' xmlns='http://www.w3.org/2000/svg'>
    <filter id='a' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse' height='7' width='16' x='11' y='32'>
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feBlend in='SourceGraphic' in2='BackgroundImageFix' mode='normal' result='shape' />
      <feGaussianBlur result='effect1_foregroundBlur_3193_260' stdDeviation='1.5' />
    </filter>
    <filter
      id='b'
      color-interpolation-filters='sRGB'
      filterUnits='userSpaceOnUse'
      height='42.0298'
      width='36.77'
      x='.514186'
      y='.342791'
    >
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        result='hardAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
      />
      <feOffset dy='1.8286' />
      <feGaussianBlur stdDeviation='2.74291' />
      <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
      <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_3193_260' />
      <feBlend in='SourceGraphic' in2='effect1_dropShadow_3193_260' mode='normal' result='shape' />
    </filter>
    <linearGradient id='c' gradientUnits='userSpaceOnUse' x1='18.8992' x2='18.8992' y1='4' y2='35.0582'>
      <stop offset='0' stop-color='#fff' />
      <stop offset='1' stop-color='#fff' stop-opacity='0' />
    </linearGradient>
    <g filter='url(#b)'>
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='currentColor'
      />
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='url(#c)'
        fill-opacity='.25'
      />
    </g>
    <g fill='#fff'>
      <path d='m25.3456 16.5854c.0235.0235.047.0235.0705.0235.047 0 .0705 0 .1175-.047.047-.0235.0705-.094.0705-.1646l-.4935-2.5382 1.8332-1.7862c.047-.047.0705-.1175.047-.188s-.0705-.1175-.141-.1175l-2.5383-.376-1.1281-2.30327c-.047-.11751-.235-.11751-.3055 0l-1.1281 2.30327-2.5383.376c-.0705 0-.1175.047-.141.1175s0 .141.047.188l1.8332 1.7862-.4231 2.5382c0 .0706.0235.1176.0705.1646.0471.047.1176.047.1881.0235l2.2797-1.1987z' />
      <path d='m17.2843 22.2729c.0235.0235.047.0235.0705.0235s.0705-.0235.094-.047c.0471-.0235.0706-.094.0706-.1645l-.4701-2.5382 1.8332-1.7862c.047-.047.0705-.1175.047-.188s-.0705-.1175-.141-.1175l-2.5383-.3761-1.1281-2.3032c-.047-.1175-.235-.1175-.3055 0l-1.1281 2.3032-2.5383.3761c-.0705 0-.1175.047-.141.1175s0 .141.047.188l1.8332 1.7862-.423 2.5382c0 .0705.0235.1175.0705.1645s.1175.047.188.0235l2.2797-1.1986z' />
    </g>
  </svg>
)

export default SvgSidebarPinTwoStars
