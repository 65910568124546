import React, { useCallback, useState } from 'react'
import styles from './Engine.module.css'
import { Form } from 'react-final-form'
import { EngineForm } from './Engine'
import { InputFormField } from '../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../common/components/Form/SelectFormField/SelectFormField'
import { FileField, languageOptions, themeOptions, themeOptionsWith3D } from './EngineHelper'
import { Button } from '../../common/components/button/Button'
import { t } from 'i18next'
import { Instructions } from './Instructions'
import classNames from 'classnames'

interface Props {
  initialValues: Partial<EngineForm>
  validate: (values: EngineForm) => Partial<EngineForm>
  onSubmit: (values: EngineForm) => void
  has3D: boolean
}

export const InstructionsAndForm: React.FC<Props> = ({ initialValues, validate, onSubmit, has3D }) => {
  const [fileError, setFileError] = useState<string | undefined>()

  const internalOnSubmit = useCallback(
    (values: EngineForm) => {
      if (values.inputFile === undefined) {
        setFileError(t('engine.value_required', 'Required field'))
        return
      }
      setFileError(undefined)
      onSubmit(values)
    },
    [onSubmit],
  )

  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepTitle}>{t('engine.login_step.lets_create', "Let's create your game")}</div>
      <Instructions />
      <Form<EngineForm> onSubmit={internalOnSubmit} initialValues={initialValues} validate={validate}>
        {({ handleSubmit, submitting, values, errors }) => (
          <form id='ENGINE_FORM' onSubmit={handleSubmit} className={styles.formContainer}>
            <div style={{ display: 'none' }}>
              <InputFormField hidden srOnlyLabel name='instructorToAdd' label={t('engine.user_email', 'Your email')} />
              <InputFormField hidden srOnlyLabel name='generatorPin' label={t('engine.pin_code', 'Pin code')} />
            </div>
            <InputFormField name='gameName' label={t('engine.game_name', 'Game name')} />
            <SelectFormField
              name='language'
              label={t('engine.language_label', 'Language')}
              placeholder={t('engine.language_placeholder', 'Select language')}
              options={languageOptions}
            />
            <SelectFormField
              name='template'
              label={t('engine.theme_label', 'Theme')}
              placeholder={t('engine.theme_placeholder', 'Select theme')}
              options={has3D ? themeOptionsWith3D : themeOptions}
            />
            <div>
              <div style={{ color: 'var(--grey-700)', display: 'inline-block', marginBottom: '10px' }}>
                {t('engine.material_file_placeholder', 'Material file')}
              </div>
              <div className={classNames(styles.fileWrapper, fileError && styles.fileWrapperWithError)}>
                <FileField name='inputFile' />
                {fileError && <div>{fileError}</div>}
              </div>
            </div>
            <Button className={styles.continueButton} variant={'big'} disabled={submitting} type='submit'>
              {t('engine.generate_button', 'Continue')}
            </Button>
          </form>
        )}
      </Form>
    </div>
  )
}
