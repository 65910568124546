import * as React from 'react'
import { SVGProps } from 'react'

const SvgSidebarPinCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' width='100%' height='100%' viewBox='3 4 32 32' xmlns='http://www.w3.org/2000/svg'>
    <filter id='a' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse' height='7' width='16' x='11' y='32'>
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feBlend in='SourceGraphic' in2='BackgroundImageFix' mode='normal' result='shape' />
      <feGaussianBlur result='effect1_foregroundBlur_3193_297' stdDeviation='1.5' />
    </filter>
    <filter
      id='b'
      color-interpolation-filters='sRGB'
      filterUnits='userSpaceOnUse'
      height='42.0298'
      width='36.77'
      x='.514186'
      y='.342791'
    >
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        result='hardAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
      />
      <feOffset dy='1.8286' />
      <feGaussianBlur stdDeviation='2.74291' />
      <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
      <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_3193_297' />
      <feBlend in='SourceGraphic' in2='effect1_dropShadow_3193_297' mode='normal' result='shape' />
    </filter>
    <linearGradient id='c' gradientUnits='userSpaceOnUse' x1='18.8992' x2='18.8992' y1='4' y2='35.0582'>
      <stop offset='0' stop-color='#fff' />
      <stop offset='1' stop-color='#fff' stop-opacity='0' />
    </linearGradient>
    <g filter='url(#b)'>
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='currentColor'
      />
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='url(#c)'
        fill-opacity='.25'
      />
    </g>
    <circle cx='19' cy='17' fill='#fff' r='7' />
  </svg>
)

export default SvgSidebarPinCircle
