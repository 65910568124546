import * as React from 'react'
import { SVGProps } from 'react'

const SvgSidebarPinSkull = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' width='100%' height='100%' viewBox='3 4 32 32' xmlns='http://www.w3.org/2000/svg'>
    <filter id='a' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse' height='7' width='16' x='11' y='32'>
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feBlend in='SourceGraphic' in2='BackgroundImageFix' mode='normal' result='shape' />
      <feGaussianBlur result='effect1_foregroundBlur_3193_324' stdDeviation='1.5' />
    </filter>
    <filter
      id='b'
      color-interpolation-filters='sRGB'
      filterUnits='userSpaceOnUse'
      height='42.0298'
      width='36.77'
      x='.514186'
      y='.342791'
    >
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        result='hardAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
      />
      <feOffset dy='1.8286' />
      <feGaussianBlur stdDeviation='2.74291' />
      <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
      <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_3193_324' />
      <feBlend in='SourceGraphic' in2='effect1_dropShadow_3193_324' mode='normal' result='shape' />
    </filter>
    <linearGradient id='c' gradientUnits='userSpaceOnUse' x1='18.8992' x2='18.8992' y1='4' y2='35.0582'>
      <stop offset='0' stop-color='#fff' />
      <stop offset='1' stop-color='#fff' stop-opacity='0' />
    </linearGradient>
    <g filter='url(#b)'>
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='currentColor'
      />
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='url(#c)'
        fill-opacity='.25'
      />
    </g>
    <g fill='#fff'>
      <path
        clip-rule='evenodd'
        d='m21.7173 10.7517c-.2469-.1234-.5185-.2222-.7901-.3209-.642-.1976-1.3581-.2963-2.0741-.2963-3.1852.074-5.7778 2.4691-5.8519 5.4321-.0247 1.1358.321 2.2222.9383 3.1111.0988.1481.2195.2963.3402.4444.0604.0741.1207.1482.1783.2222.2469.2716.5185.5186.8148.7655.4198.2963.6173.7654.6173 1.2592v.0494c0 .5432.5679 1.0124 1.1605 1.0124h3.8519c.5926 0 1.037-.4692 1.037-1.0124v-.0494c0-.4938.2716-.9629.6914-1.2592 1.4074-1.0124 2.3457-2.6173 2.3457-4.3951.0246-2.1728-1.3087-4.0247-3.2593-4.963zm-5.1852 7.5062c-.8642 0-1.5802-.716-1.5802-1.5802s.716-1.5803 1.5802-1.5803 1.5803.7161 1.5803 1.5803c0 .8889-.7161 1.5802-1.5803 1.5802zm3.0864 2.2469h-1.2345c-.1976 0-.3457-.2469-.2223-.4197l.6173-1.0864c.0988-.1729.3704-.1976.4692 0l.6172 1.0864c.0988.1728-.0493.4197-.2469.4197zm1.679-2.2469c-.8642 0-1.5802-.716-1.5802-1.5802s.716-1.5803 1.5802-1.5803 1.5803.7161 1.5803 1.5803c0 .8889-.6914 1.5802-1.5803 1.5802z'
        fill-rule='evenodd'
      />
      <path d='m16.8284 24.1345c.3409 0 .6173-.2764.6173-.6173s-.2764-.6173-.6173-.6173-.6173.2764-.6173.6173.2764.6173.6173.6173z' />
      <path d='m18.9272 23.5172c0 .3409-.2764.6173-.6173.6173s-.6173-.2764-.6173-.6173.2764-.6173.6173-.6173.6173.2764.6173.6173z' />
      <path d='m20.458 23.5172c0 .3457-.2716.6173-.6173.6173-.3456 0-.6172-.2716-.6172-.6173s.2716-.6173.6172-.6173c.3457 0 .6173.2716.6173.6173z' />
      <path d='m21.8901 23.5172c0 .3409-.2763.6173-.6173.6173-.3409 0-.6172-.2764-.6172-.6173s.2763-.6173.6172-.6173c.341 0 .6173.2764.6173.6173z' />
    </g>
  </svg>
)

export default SvgSidebarPinSkull
