import { useTranslation } from 'react-i18next'
import { ExploreSubtask } from '../../../../../../../types/commonTypes'
import { SettingsToggle } from '../../AdvancedSettings/SettingsToggle'

type ExploreSubtaskProps = {
  value: ExploreSubtask
  name: string
  viewOnly: boolean
}

export const ExploreSubtaskFields: React.FC<ExploreSubtaskProps> = ({ value, name, viewOnly }) => {
  const { t } = useTranslation()

  return (
    <>
      <SettingsToggle
        name={`${name}.data.ackRequired`}
        title={t('game_editor.tasks.require_ack', 'Require acknowledgement')}
        description={t(
          'game_editor.tasks.explore_task.require_ack_disabled',
          'Require players to mark the task content as having seen it.',
        )}
        descriptionChecked={t(
          'game_editor.tasks.explore_task.require_ack_enabled',
          'Players are required to mark the task content as seen in order to complete the explora task.',
        )}
        disabled={viewOnly}
      />
    </>
  )
}
