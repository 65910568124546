import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderBlock } from '../../common/components/loaders/LoaderBlock/LoaderBlock'
import { GamesOverview } from '../../composites/GamesOverview/GamesOverview'
import { useGameData } from '../../contexts/CommunityGamesContextProvider'
import { GamesFilterSettings } from '../../types/commonTypes'
import styles from './Community.module.css'
import { LibraryInfo } from '../../common/components/LibraryInfo/LibraryInfo'

const COMMUNITY_FILTERS: GamesFilterSettings = {
  age: true,
  approvedBySeppo: true,
  language: true,
  subject: true,
}

export const Community: React.FC = () => {
  const { t } = useTranslation()
  const gameData = useGameData()

  return (
    <div className={styles.container}>
      <LibraryInfo infoTitle={t('library_info.community.header', 'Community library:')}>
        {t(
          'library_info.community.body',
          'Join the community of creators! Discover and filter through user-created games, and add your own to inspire others.',
        )}
      </LibraryInfo>
      {!gameData.initialFetchDone ? (
        <LoaderBlock variant='secondary' />
      ) : (
        <GamesOverview
          title={t('community_page.title', 'Community library')}
          libraryMode
          gameData={gameData}
          emptyContent={{
            title: t('community_page.empty.title', 'There are no games in the community yet'),
            subtitle: t('community_page.empty.content', 'Create a new game'),
          }}
          filterFormSettings={COMMUNITY_FILTERS}
        />
      )}
    </div>
  )
}
