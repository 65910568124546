import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { Fragment } from 'react'
import {
  AnswerStateEnum,
  MissingWordAnswerOption,
  ObjectGeneric,
  ReceivedAnswer,
} from '../../../../../../../../../types/commonTypes'
import { anyMissingWordFieldRegex } from '../../../../../../../../../util/missingWord'
import { safeIsNullOrEmpty } from '../../../../../../../../../util/string'
import styles from './MissingWordAnswer.module.css'
import { AnswerPoints } from '../AnswerPoints'
import { TimeExceededInfo } from '../TimeExceededInfo'
import { MissingWordCorrect } from './MissingWordCorrect'
import { MissingWordIncorrect } from './MissingWordIncorrect'

type MissingWordAnswerProps = {
  answer: ReceivedAnswer
  answerOptions: MissingWordAnswerOption[]
  taskDescription: string
  noPointsGame?: boolean
}

const hasContent = (answer: ObjectGeneric<string>) => {
  return Object.keys(answer).some((key) => !safeIsNullOrEmpty(answer[key]))
}

const formatWord = (word: string) => word.toUpperCase().trim()

export const MissingWordAnswer: React.FC<MissingWordAnswerProps> = ({
  answer,
  answerOptions,
  taskDescription,
  noPointsGame,
}) => {
  const answerObject = answer.answer as unknown as ObjectGeneric<string>

  if (!hasContent(answerObject)) {
    return <TimeExceededInfo />
  }

  const htmlParts = taskDescription.replaceAll(anyMissingWordFieldRegex, ';|;').split(';|;')

  return (
    <div
      className={classNames(
        styles.answerOptionsTable,
        answer.state === AnswerStateEnum.TIME_FINISHED && styles.timeExceededFadeout,
      )}
    >
      {Object.keys(answerObject).map((key, index) => {
        const word = answerObject[key]
        const correctWord = answerOptions[index].word
        const isCorrect =
          !safeIsNullOrEmpty(word) &&
          (correctWord === '***' || correctWord.split(';').map(formatWord).includes(formatWord(word)))
        return (
          <Fragment key={`${answer.id}_missingWord_${index}`}>
            <div className={classNames(styles.answerOptionRow, styles.answerOptionRowContent, 'tiny')}>
              <div
                className={styles.answerOptionContainer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(htmlParts[index]),
                }}
              />
            </div>
            <div className={styles.answerOptionRow}>
              {isCorrect ? (
                <MissingWordCorrect word={word} />
              ) : (
                <MissingWordIncorrect
                  word={word}
                  correct={correctWord}
                  timeExceeded={answer.state === AnswerStateEnum.TIME_FINISHED}
                />
              )}
              <AnswerPoints
                points={isCorrect ? answerOptions[index].points ?? 0 : 0}
                hidePoints={noPointsGame || answer.state === AnswerStateEnum.TIME_FINISHED}
              />
            </div>
          </Fragment>
        )
      })}
      <div className={classNames(styles.answerOptionRow, styles.answerOptionRowContent, 'tiny')}>
        <div
          className={styles.answerOptionContainer}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(htmlParts[htmlParts.length - 1]),
          }}
        />
      </div>
    </div>
  )
}
