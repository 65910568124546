import * as React from 'react'
import { SVGProps } from 'react'

const SvgSidebarPinQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' width='100%' height='100%' viewBox='3 4 32 32' xmlns='http://www.w3.org/2000/svg'>
    <filter id='a' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse' height='7' width='16' x='11' y='32'>
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feBlend in='SourceGraphic' in2='BackgroundImageFix' mode='normal' result='shape' />
      <feGaussianBlur result='effect1_foregroundBlur_3193_306' stdDeviation='1.5' />
    </filter>
    <filter
      id='b'
      color-interpolation-filters='sRGB'
      filterUnits='userSpaceOnUse'
      height='42.0298'
      width='36.77'
      x='.514186'
      y='.342791'
    >
      <feFlood flood-opacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        result='hardAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
      />
      <feOffset dy='1.8286' />
      <feGaussianBlur stdDeviation='2.74291' />
      <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
      <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_3193_306' />
      <feBlend in='SourceGraphic' in2='effect1_dropShadow_3193_306' mode='normal' result='shape' />
    </filter>
    <linearGradient id='c' gradientUnits='userSpaceOnUse' x1='18.8992' x2='18.8992' y1='4' y2='35.0582'>
      <stop offset='0' stop-color='#fff' />
      <stop offset='1' stop-color='#fff' stop-opacity='0' />
    </linearGradient>
    <g filter='url(#b)'>
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='currentColor'
      />
      <path
        d='m18.9598 35.0582 9.1138-9.1139c4.9771-4.977 4.9771-13.1213-.0646-18.16302-5.0417-5.04171-13.186-5.0417-18.22772 0-5.0417 5.04172-5.04171 13.18602 0 18.22772.06463.0646 9.17852 9.0492 9.17852 9.0492z'
        fill='url(#c)'
        fill-opacity='.25'
      />
    </g>
    <path
      d='m14.2 14.15c0-.5867.2023-1.1734.5867-1.7804.3844-.6069.9509-1.0924 1.6792-1.4971.7486-.4046 1.5983-.5867 2.5896-.5867.9104 0 1.7197.1619 2.4277.4856.7081.3237 1.2342.7688 1.6185 1.3352.3844.5665.5665 1.1937.5665 1.8411 0 .526-.1011.9913-.3237 1.3757-.2225.4046-.4855.7283-.7688 1.0318-.3034.2832-.8294.7688-1.6185 1.4566-.2225.1821-.3844.3642-.526.5058-.1214.1416-.2225.2833-.2832.4046-.0607.1214-.1214.2428-.1416.3642-.0405.1214-.081.3237-.1619.6272-.1214.6474-.5058.9711-1.1329.9711-.3237 0-.607-.1012-.8295-.3237-.2226-.2023-.3439-.526-.3439-.9307 0-.526.0809-.9711.2427-1.3352.1619-.3844.3844-.7081.6474-.9914.263-.2832.6474-.6271 1.0925-1.0317.4046-.344.6879-.607.87-.7891.182-.182.3237-.3641.445-.5867.1214-.2225.1821-.4451.1821-.7081 0-.4855-.182-.9104-.5664-1.2543s-.87-.5058-1.4567-.5058c-.6879 0-1.2139.1619-1.5376.5058s-.6069.8497-.8294 1.5173c-.2226.6879-.6272 1.0521-1.2139 1.0521-.3439 0-.6474-.1214-.8902-.3642-.2023-.2428-.3237-.5058-.3237-.789zm4.633 10.1358c-.3844 0-.7284-.1214-1.0116-.3641-.2832-.2428-.4249-.5867-.4249-1.0318 0-.3844.1417-.7081.4249-.9711s.6272-.4046 1.0318-.4046.7283.1416 1.0116.4046c.263.263.4046.5867.4046.9711 0 .4248-.1416.7688-.4249 1.0115-.3237.263-.6474.3844-1.0115.3844z'
      fill='#fff'
    />
  </svg>
)

export default SvgSidebarPinQuestion
