import * as React from 'react'
import { SVGProps } from 'react'

const SvgSidebarPinLocked = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='3 4 32 32' fill='none'>
    <g filter='url(#filter0_d_3445_352)'>
      <circle cx='18.8037' cy='16.8038' r='6.39876' fill='#CC3E76' />
      <path
        d='M18.9598 35.0582L28.0736 25.9443C33.0507 20.9673 33.0507 12.823 28.009 7.78128C22.9673 2.73957 14.823 2.73958 9.78128 7.78128C4.73958 12.823 4.73957 20.9673 9.78128 26.009C9.84591 26.0736 18.9598 35.0582 18.9598 35.0582ZM15.0169 13.0169C17.1499 10.8839 20.6403 10.8839 22.7734 13.0169C24.9064 15.1499 24.9064 18.6403 22.7734 20.7734C22.5795 20.9673 22.3855 21.1612 22.127 21.2905C22.127 20.5148 21.7392 19.7392 21.1574 19.1574C19.9293 17.9293 17.8609 17.9293 16.6328 19.1574C16.0511 19.7392 15.7279 20.5795 15.6633 21.2905C15.4694 21.0965 15.2108 20.9673 15.0169 20.7734C12.8839 18.6403 12.8839 15.1499 15.0169 13.0169Z'
        fill='#CC3E76'
      />
      <path
        d='M18.9598 35.0582L28.0736 25.9443C33.0507 20.9673 33.0507 12.823 28.009 7.78128C22.9673 2.73957 14.823 2.73958 9.78128 7.78128C4.73958 12.823 4.73957 20.9673 9.78128 26.009C9.84591 26.0736 18.9598 35.0582 18.9598 35.0582ZM15.0169 13.0169C17.1499 10.8839 20.6403 10.8839 22.7734 13.0169C24.9064 15.1499 24.9064 18.6403 22.7734 20.7734C22.5795 20.9673 22.3855 21.1612 22.127 21.2905C22.127 20.5148 21.7392 19.7392 21.1574 19.1574C19.9293 17.9293 17.8609 17.9293 16.6328 19.1574C16.0511 19.7392 15.7279 20.5795 15.6633 21.2905C15.4694 21.0965 15.2108 20.9673 15.0169 20.7734C12.8839 18.6403 12.8839 15.1499 15.0169 13.0169Z'
        fill='url(#paint0_linear_3445_352)'
        fill-opacity='0.25'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M22.4062 16.2195C21.7156 16.2195 21.0533 16.5057 20.565 17.0152C20.0766 17.5247 19.8023 18.2157 19.8023 18.9363V19.5724C19.1955 19.7962 18.7607 20.4 18.7607 21.1097V24.3698C18.7607 25.2701 19.4602 25.9999 20.3231 25.9999H24.4893C25.3522 25.9999 26.0517 25.2701 26.0517 24.3698V21.1097C26.0517 20.4 25.6169 19.7962 25.0101 19.5724V18.9363C25.0101 18.2157 24.7358 17.5247 24.2474 17.0152C23.7591 16.5057 23.0968 16.2195 22.4062 16.2195ZM23.9685 19.4796V18.9363C23.9685 18.5039 23.8039 18.0893 23.5109 17.7836C23.2179 17.4779 22.8206 17.3062 22.4062 17.3062C21.9918 17.3062 21.5945 17.4779 21.3015 17.7836C21.0085 18.0893 20.8439 18.5039 20.8439 18.9363V19.4796H23.9685ZM20.3231 20.5663C20.0355 20.5663 19.8023 20.8096 19.8023 21.1097V24.3698C19.8023 24.6699 20.0355 24.9132 20.3231 24.9132H24.4893C24.7769 24.9132 25.0101 24.6699 25.0101 24.3698V21.1097C25.0101 20.8096 24.7769 20.5663 24.4893 20.5663H20.3231ZM22.4062 23.419C22.7657 23.419 23.0572 23.1149 23.0572 22.7398C23.0572 22.3647 22.7657 22.0606 22.4062 22.0606C22.0467 22.0606 21.7552 22.3647 21.7552 22.7398C21.7552 23.1149 22.0467 23.419 22.4062 23.419Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M16.867 8C17.1773 8 17.4289 8.25591 17.4289 8.57158V10.315C18.7129 10.4462 19.9199 11.0241 20.8404 11.9605C21.4603 12.591 21.9204 13.3536 22.1942 14.1834C22.3073 14.5262 22.04 14.859 21.6845 14.859H21.6549C21.3987 14.859 21.1784 14.6812 21.0906 14.4363C20.8682 13.8155 20.5139 13.245 20.0457 12.7688C19.2027 11.9113 18.0593 11.4295 16.867 11.4295C15.6747 11.4295 14.5313 11.9113 13.6882 12.7688C12.8452 13.6263 12.3716 14.7894 12.3716 16.0022C12.3716 17.2149 12.8452 18.378 13.6882 19.2355C14.5313 20.0931 15.6747 20.5748 16.867 20.5748C17.1661 20.5748 17.4289 20.8079 17.4289 21.1121V21.1464V21.6894V23.4328C17.4289 23.7484 17.1773 24.0043 16.867 24.0043C16.5566 24.0043 16.3051 23.7484 16.3051 23.4328V21.6894C15.0211 21.5582 13.8141 20.9802 12.8936 20.0439C11.973 19.1075 11.4049 17.8798 11.2759 16.5738H9.56193C9.25158 16.5738 9 16.3178 9 16.0022C9 15.6865 9.25158 15.4306 9.56193 15.4306H11.2759C11.4049 14.1246 11.973 12.8968 12.8936 11.9605C13.8141 11.0241 15.0211 10.4462 16.3051 10.315V8.57158C16.3051 8.25591 16.5566 8 16.867 8ZM15.2776 14.3855C15.6991 13.9567 16.2709 13.7158 16.867 13.7158C17.4631 13.7158 18.0348 13.9567 18.4564 14.3855C18.8779 14.8143 19.1147 15.3958 19.1147 16.0022C19.1147 16.6085 18.8779 17.1901 18.4564 17.6189C18.0348 18.0476 17.4631 18.2885 16.867 18.2885C16.2709 18.2885 15.6991 18.0476 15.2776 17.6189C14.8561 17.1901 14.6193 16.6085 14.6193 16.0022C14.6193 15.3958 14.8561 14.8143 15.2776 14.3855ZM16.867 14.859C16.5689 14.859 16.2831 14.9794 16.0723 15.1938C15.8615 15.4082 15.7431 15.699 15.7431 16.0022C15.7431 16.3054 15.8615 16.5961 16.0723 16.8105C16.2831 17.0249 16.5689 17.1453 16.867 17.1453C17.1651 17.1453 17.4509 17.0249 17.6617 16.8105C17.8724 16.5961 17.9908 16.3054 17.9908 16.0022C17.9908 15.699 17.8724 15.4082 17.6617 15.1938C17.4509 14.9794 17.1651 14.859 16.867 14.859Z'
        fill='white'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_3445_352'
        x='-2.48581'
        y='0.342791'
        width='42.9716'
        height='42.9716'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='1.8286' />
        <feGaussianBlur stdDeviation='2.74291' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3445_352' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3445_352' result='shape' />
      </filter>
      <filter
        id='filter1_f_3445_352'
        x='11'
        y='32'
        width='16'
        height='7'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_3445_352' />
      </filter>
      <linearGradient
        id='paint0_linear_3445_352'
        x1='18.8992'
        y1='4'
        x2='18.8992'
        y2='35.0582'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='white' />
        <stop offset='1' stop-color='white' stop-opacity='0' />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgSidebarPinLocked
