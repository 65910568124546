import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AnswerStateEnum, Evaluation } from '../../../../../../../../../types/commonTypes'
import { compareStrings } from '../../../../../../../../../util/string'
import sidebarStyles from '../../../../../EditorSidebar.module.css'
import { EvaluationSortBy } from '../../../../types'
import { EvaluationListByState } from './EvaluationListByState'
import styles from './TaskEvaluationList.module.css'

type TaskEvaluationListProps = {
  evaluations: Evaluation[]
  onClickPlayer: (playerId: number) => void
  selectedPlayerId?: number
  noPointsGame?: boolean
  isExplore?: boolean
  isCompact: boolean
  sortBy: EvaluationSortBy
}

const getSortFn = (sortBy: EvaluationSortBy): ((a: Evaluation, b: Evaluation) => number) => {
  switch (sortBy) {
    case EvaluationSortBy.AnswerTimestamp_asc:
      return (a: Evaluation, b: Evaluation) =>
        a.answers[0].answer.completedAt.localeCompare(b.answers[0].answer.completedAt)
    case EvaluationSortBy.AnswerTimestamp_desc:
      return (a: Evaluation, b: Evaluation) =>
        b.answers[0].answer.completedAt.localeCompare(a.answers[0].answer.completedAt)
    case EvaluationSortBy.PlayerName_asc:
      return (a: Evaluation, b: Evaluation) => compareStrings(a.player.nickName, b.player.nickName)
    case EvaluationSortBy.PlayerName_desc:
      return (a: Evaluation, b: Evaluation) => compareStrings(b.player.nickName, a.player.nickName)
    default:
      return (a: Evaluation, b: Evaluation) => 0
  }
}

export const TaskEvaluationList: React.FC<TaskEvaluationListProps> = ({
  evaluations,
  onClickPlayer,
  noPointsGame,
  isExplore,
  selectedPlayerId,
  isCompact,
  sortBy,
}) => {
  const { t } = useTranslation()

  const playersAnswersReady = useMemo(
    () => evaluations.filter((data) => data.state === AnswerStateEnum.READY).sort(getSortFn(sortBy)),
    [evaluations, sortBy],
  )
  const playersAnswersGraded = useMemo(
    () => evaluations.filter((data) => data.state === AnswerStateEnum.GRADED).sort(getSortFn(sortBy)),
    [evaluations, sortBy],
  )
  const playersAnswersInRevision = useMemo(
    () => evaluations.filter((data) => data.state === AnswerStateEnum.REVISION).sort(getSortFn(sortBy)),
    [evaluations, sortBy],
  )
  const playersAnswersTimeFinished = useMemo(
    () => evaluations.filter((data) => data.state === AnswerStateEnum.TIME_FINISHED).sort(getSortFn(sortBy)),
    [evaluations, sortBy],
  )

  return (
    <div className={styles.taskEvaluationContainer}>
      {evaluations.length === 0 && (
        <p className={sidebarStyles.emptyStateInfo}>
          {t('game_editor.sidebar.evaluation_list_panel.no_answers_info', 'No answers for selected task')}
        </p>
      )}
      <EvaluationListByState
        icon='view'
        title={t('game_editor.sidebar.evaluation_list_panel.answers_waiting_for_evaluation_title', {
          defaultValue: 'Waiting for evaluation (%{players_count})',
          players_count: playersAnswersReady.length,
        })}
        evaluations={playersAnswersReady}
        onClickPlayer={onClickPlayer}
        selectedPlayerId={selectedPlayerId}
        noPointsGame={noPointsGame}
        isExplore={isExplore}
        state={AnswerStateEnum.READY}
        isCompact={isCompact}
      />
      <EvaluationListByState
        icon='recents'
        title={t('game_editor.sidebar.evaluation_list_panel.answers_time_finished_title', {
          defaultValue: 'No answer (%{players_count})',
          players_count: playersAnswersTimeFinished.length,
        })}
        evaluations={playersAnswersTimeFinished}
        onClickPlayer={onClickPlayer}
        selectedPlayerId={selectedPlayerId}
        noPointsGame={noPointsGame}
        isExplore={isExplore}
        state={AnswerStateEnum.TIME_FINISHED}
        isCompact={isCompact}
      />
      <EvaluationListByState
        icon='timerGradient'
        title={t('game_editor.sidebar.evaluation_list_panel.answers_in_revision_title', {
          defaultValue: 'Revision requested (%{players_count})',
          players_count: playersAnswersInRevision.length,
        })}
        evaluations={playersAnswersInRevision}
        onClickPlayer={onClickPlayer}
        selectedPlayerId={selectedPlayerId}
        noPointsGame={noPointsGame}
        isExplore={isExplore}
        state={AnswerStateEnum.REVISION}
        isCompact={isCompact}
      />
      <EvaluationListByState
        icon='circleCheckmark'
        title={
          isExplore
            ? t('game_editor.sidebar.evaluation_list_panel.answers_viewed_title', {
                defaultValue: 'Viewed (%{players_count})',
                players_count: playersAnswersGraded.length,
              })
            : t('game_editor.sidebar.evaluation_list_panel.answers_graded_title', {
                defaultValue: 'Evaluated (%{players_count})',
                players_count: playersAnswersGraded.length,
              })
        }
        evaluations={playersAnswersGraded}
        onClickPlayer={onClickPlayer}
        selectedPlayerId={selectedPlayerId}
        noPointsGame={noPointsGame}
        isExplore={isExplore}
        state={AnswerStateEnum.GRADED}
        isCompact={isCompact}
      />
    </div>
  )
}
