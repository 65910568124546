import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderBlock } from '../../common/components/loaders/LoaderBlock/LoaderBlock'
import { GamesOverview } from '../../composites/GamesOverview/GamesOverview'
import { useGameData } from '../../contexts/TemplateGamesContextProvider'
import { GamesFilterSettings } from '../../types/commonTypes'
import styles from './Templates.module.css'
import { LibraryInfo } from '../../common/components/LibraryInfo/LibraryInfo'

const TEMPLATES_FILTERS: GamesFilterSettings = {
  age: true,
  language: true,
  subject: true,
}

export const Templates: React.FC = () => {
  const { t } = useTranslation()
  const gameData = useGameData()

  return (
    <div className={styles.container}>
      <LibraryInfo infoTitle={t('library_info.templates.header', 'Templates:')}>
        {t(
          'library_info.templates.body',
          'Explore our templates library – your resource for expertly crafted games designed to elevate corporate training!',
        )}
      </LibraryInfo>
      {!gameData.initialFetchDone ? (
        <LoaderBlock variant='secondary' />
      ) : (
        <GamesOverview
          title={t('templates_page.title', 'Game templates')}
          libraryMode
          gameData={gameData}
          emptyContent={{
            title: t('templates.empty.title', 'There are no templates yet'),
            subtitle: t('templates.empty.content', 'Create a new game'),
          }}
          filterFormSettings={TEMPLATES_FILTERS}
        />
      )}
    </div>
  )
}
