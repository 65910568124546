import { TFunction } from 'i18next'
import {
  AnswerStateEnum,
  Evaluation,
  Player,
  PlayerAnswer,
  ReceivedAnswer,
  Subtask,
  Task,
} from '../../../../../types/commonTypes'
import { omitProperty } from '../../../../../util/functional'
import { PlayerSummary, TaskSummary } from './types'

export const getTaskSummaries = (tasks: Task[]): TaskSummary[] => {
  return tasks.map((task) => ({ task, ...getTaskStateOverview(task) }))
}

export const getPlayerSummaries = (players: Player[] = [], tasks: Task[] = []): PlayerSummary[] => {
  return players.map((player) => ({ player, ...getPlayerStateOverview(player.id, tasks) }))
}

export const getEvaluationsForTask = (task: Task, players: Player[]): Evaluation[] => {
  return players.reduce((evaluations: Evaluation[], player: Player) => {
    const evaluation = getEvaluationForTaskAndPlayer(task, player)
    return evaluation == null ? evaluations : [...evaluations, evaluation]
  }, [])
}

export const getEvaluationsForPlayer = (player: Player, tasks: Task[] = []): Evaluation[] => {
  return tasks.reduce((evaluations: Evaluation[], task: Task) => {
    const evaluation = getEvaluationForTaskAndPlayer(task, player)
    return evaluation == null ? evaluations : [...evaluations, evaluation]
  }, [])
}

const getTaskStateOverview = (task: Task): Omit<TaskSummary, 'task'> => {
  const playerStateMap: { [playerId: number]: AnswerStateEnum } = {}

  task.subtasks.forEach((currSubtask) => {
    ;(currSubtask.answers ?? []).forEach(({ state, userId }) => {
      if (state === AnswerStateEnum.REVISION) {
        playerStateMap[userId] = AnswerStateEnum.REVISION
      } else if (state === AnswerStateEnum.READY && playerStateMap[userId] !== AnswerStateEnum.REVISION) {
        playerStateMap[userId] = AnswerStateEnum.READY
      } else if (![AnswerStateEnum.REVISION, AnswerStateEnum.READY].includes(playerStateMap[userId])) {
        playerStateMap[userId] = state
      }
    })
  })

  const totalAnswers = Object.keys(playerStateMap).length
  const gradedAnswers = Object.values(playerStateMap).filter((state) => state === AnswerStateEnum.GRADED).length
  const needsReview = Object.values(playerStateMap).some((state) => state === AnswerStateEnum.READY)

  return { totalAnswers, gradedAnswers, needsReview }
}

const getPlayerStateOverview = (playerId: number, tasks: Task[]): Omit<PlayerSummary, 'player'> => {
  let totalScore = 0
  let tasksAnswered = 0
  let tasksUngraded = 0
  let needsReview = false
  tasks.forEach((task) => {
    const playerAnswers: ReceivedAnswer[] = []
    task.subtasks.forEach((subtask) => {
      playerAnswers.push(...(subtask.answers ?? []).filter((answer) => answer.userId === playerId))
    })
    const generalState = getAnswersGeneralStatus(playerAnswers)
    if (generalState == null || [AnswerStateEnum.IN_PROGRESS, AnswerStateEnum.REVISION].includes(generalState)) {
      return
    } else {
      tasksAnswered += 1
      if (generalState === AnswerStateEnum.READY) {
        needsReview = true
        tasksUngraded += 1
      } else if (generalState === AnswerStateEnum.GRADED) {
        totalScore += playerAnswers.reduce((sum, answer) => sum + answer.points, 0)
      }
    }
  })
  return {
    totalScore,
    tasksAnswered,
    tasksUngraded,
    needsReview,
  }
}

const getAnswersGeneralStatus = (answers: ReceivedAnswer[]): AnswerStateEnum | null => {
  if (answers.length === 0) {
    return null
  }
  if (answers.some((answer) => answer != null && answer.state === AnswerStateEnum.REVISION)) {
    return AnswerStateEnum.REVISION
  }
  if (answers.some((answer) => answer != null && answer.state === AnswerStateEnum.READY)) {
    return AnswerStateEnum.READY
  }
  return answers[0].state
}

const getEvaluationForTaskAndPlayer = (task: Task, player: Player): Evaluation | undefined => {
  const playerAnswers = getPlayerAnswersForPlayer(task, player.id)
  if (playerAnswers.length === 0) {
    return undefined
  }
  const { subtasks: _, ...taskRest } = task
  const overallState = getPlayerAnswerGeneralStatus(playerAnswers)
  return {
    task: { ...taskRest, subtasksCount: task.subtasks.length },
    player,
    answers: [AnswerStateEnum.REVISION, AnswerStateEnum.TIME_FINISHED].includes(overallState)
      ? playerAnswers.map((playerAnswer) => ({
          ...playerAnswer,
          answer: {
            ...playerAnswer.answer,
            state: overallState,
          },
        }))
      : playerAnswers,
    state: overallState,
    totalPoints: getTotalPointsForPlayerAnswers(playerAnswers),
  }
}

const getPlayerAnswersForPlayer = (task: Task, playerId: number): PlayerAnswer[] => {
  return task.subtasks.reduce((answers: PlayerAnswer[], currSubtask) => {
    const playerAnswer = currSubtask.answers?.find((answer) => answer.userId === playerId)
    return playerAnswer == null
      ? answers
      : [...answers, { answer: playerAnswer, subtask: omitProperty<Subtask, Subtask>(currSubtask, 'answers') }]
  }, [])
}

const getPlayerAnswerGeneralStatus = (playerAnswers: PlayerAnswer[]): AnswerStateEnum => {
  if (
    playerAnswers.some((playerAnswer) => playerAnswer != null && playerAnswer.answer.state === AnswerStateEnum.REVISION)
  ) {
    return AnswerStateEnum.REVISION
  }
  if (
    playerAnswers.some((playerAnswer) => playerAnswer != null && playerAnswer.answer.state === AnswerStateEnum.READY)
  ) {
    return AnswerStateEnum.READY
  }
  return playerAnswers[0].answer.state
}

const getTotalPointsForPlayerAnswers = (playerAnswers: PlayerAnswer[]): number => {
  return playerAnswers.reduce((sum, playerAnswer) => sum + playerAnswer.answer.points, 0)
}

export const getShortAnswerStatusText = (status: AnswerStateEnum, t: TFunction, isExplore = false) => {
  switch (status) {
    case AnswerStateEnum.READY:
      return t('game_editor.sidebar.evaluation_list_panel.answer_status_short_review', 'Review')
    case AnswerStateEnum.REVISION:
      return t('game_editor.sidebar.evaluation_list_panel.answer_status_short_in_revision', 'Revision requested')
    case AnswerStateEnum.IN_PROGRESS:
      return t('game_editor.sidebar.evaluation_list_panel.answer_status_short_in_progress', 'In progress')
    case AnswerStateEnum.TIME_FINISHED:
      return t('game_editor.sidebar.evaluation_list_panel.answer_status_short_time_exceeded', 'Time exceeded')
    default:
      return isExplore
        ? t('game_editor.sidebar.evaluation_list_panel.answer_status_short_viewed', 'Viewed')
        : t('game_editor.sidebar.evaluation_list_panel.answer_status_short_graded', 'Evaluated')
  }
}
