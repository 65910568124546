import React, { PropsWithChildren, useEffect } from 'react'
import styles from './LibraryInfo.module.css'
import { BannerWithChildren } from '../banners/BannerWithChildren'
import Carousel, { ButtonGroupProps } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useWordPressData } from '../../../contexts/WordPressContextProvider'
import i18n from '../../../i18n'
import { WPInfomercial } from '../../../types/commonTypes'
import { RoundButton } from '../button/RoundButton'

type LibraryInfoProps = {
  infoTitle: string
  showCarousel?: boolean
}

const getTranslation = (wpData: WPInfomercial) => {
  const language = i18n.language
  switch (language) {
    case 'en':
      return wpData.descriptionEn
    case 'fi':
      return wpData.descriptionFi
    default:
      return wpData.descriptionEn
  }
}

const CustomButtons = ({ next, previous, carouselState }: ButtonGroupProps) => {
  return (
    <div className={styles.customButtonContainer}>
      <RoundButton
        style={{ display: carouselState?.currentSlide === (carouselState?.totalItems || 1) - 1 ? 'none' : 'flex' }}
        color='opaque'
        icon={'arrowRight'}
        className={styles.roundbuttonRight}
        onClick={next}
      />
      <RoundButton
        style={{ display: carouselState?.currentSlide === 0 ? 'none' : 'flex' }}
        color='opaque'
        icon={'arrowLeft'}
        className={styles.roundbuttonLeft}
        onClick={previous}
      />
    </div>
  )
}

export const LibraryInfo: React.FC<PropsWithChildren<LibraryInfoProps>> = ({
  infoTitle,
  showCarousel = true,
  children,
}) => {
  const { wpData, refreshWPData, loading } = useWordPressData()

  useEffect(() => {
    refreshWPData()
  }, [refreshWPData])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  }

  return (
    <div className={styles.headerContainer}>
      <div className={styles.bannerContainer}>
        <div className={styles.introBanner}>
          <BannerWithChildren title={infoTitle}>
            <div className={styles.firstBannerBody}>{children}</div>
          </BannerWithChildren>
        </div>
        {!loading && showCarousel && wpData && wpData.infomercials && wpData.infomercials[0] && (
          <div className={styles.searchBanner}>
            <BannerWithChildren color='secondary' hasCarousel>
              <div>
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  arrows={false}
                  customButtonGroup={<CustomButtons />}
                  autoPlay={true}
                  rewind={true}
                  rewindWithAnimation={true}
                  autoPlaySpeed={8000}
                >
                  {wpData.infomercials.map((infomercial) => {
                    return (
                      <a href={infomercial.linkUrl} target='_blank' rel='noreferrer' key={Math.random()}>
                        <div
                          className={styles.carouselBackground}
                          style={{ backgroundImage: `url(${infomercial.imageUrl})` }}
                        >
                          {infomercial.showDescriptionText && (
                            <div className={styles.carouselTitle}>{getTranslation(infomercial)}</div>
                          )}
                        </div>
                      </a>
                    )
                  })}
                </Carousel>
              </div>
            </BannerWithChildren>
          </div>
        )}
      </div>
    </div>
  )
}
