import React, { useCallback } from 'react'
import styles from './Engine.module.css'
import { EngineRunState, EngineRunStateItem, EngineRunStateOrder } from './EngineHelper'
import DoneIcon from '../../assets/images/engine/DoneIcon.svg'
import InProgressIcon from '../../assets/images/engine/InProgressIcon.svg'
import { Instructions } from './Instructions'
import { t } from 'i18next'
import { ObjectAny } from '../../types/commonTypes'
import classNames from 'classnames'

interface Props {
  runState: Partial<EngineRunState> | null
}

const statusLabels: ObjectAny = {
  fileUploaded: t('engine.status_label.file_uploaded', 'Studying the material'),
  matchPairsCreated: t('engine.status_label.match_pairs_created', 'Match pairs tasks'),
  multiChoiceCreated: t('engine.status_label.multichoice_created', 'Multichoice tasks'),
  checkBoxCreated: t('engine.status_label.checkbox_created', 'Checkbox tasks'),
  missingWordCreated: t('engine.status_label.missing_word_created', 'Missing word tasks'),
  creativeCreated: t('engine.status_label.creative_created', 'Creative tasks'),
  rawGameCreated: t('engine.status_label.raw_game_created', 'Publish the raw tasks game'),
  firstGameChoiceCreated: t('engine.status_label.first_choice_created', 'First iteration'),
  secondGameChoiceCreated: t('engine.status_label.second_choice_created', 'Second option'),
  thirdGameChoiceCreated: t('engine.status_label.third_choice_created', 'Third option'),
  boardImagesCreated: t('engine.status_label.board_images_created', 'Game board artwork'),
}

const PARALLEL_TASKS: EngineRunStateItem[] = [
  'fileUploaded',
  'matchPairsCreated',
  'multiChoiceCreated',
  'checkBoxCreated',
  'missingWordCreated',
  'creativeCreated',
]

export const GeneratingStatus: React.FC<Props> = ({ runState }) => {
  const statusRowFor = useCallback(
    (key: EngineRunStateItem) => {
      if (!runState) return <div className={styles.statusIndicator} />

      if (
        key === 'rawGameCreated' &&
        !runState[key] &&
        PARALLEL_TASKS.every((t) => {
          return runState[t]
        })
      ) {
        return (
          <>
            <img className={classNames(styles.statusIndicator, styles.spinWithIntervals)} alt='' src={InProgressIcon} />
            <div>{statusLabels[key]}</div>
            <div className={styles.statusShortLabel}>{t('engine.status_label.processing', 'Processing')}</div>
          </>
        )
      }

      return runState[key] ? (
        <>
          <img className={styles.statusIndicator} alt='' src={DoneIcon} />
          <div>{statusLabels[key]}</div>
          <div className={styles.statusShortLabel}>{t('engine.status_label.done', 'Done')}</div>
        </>
      ) : key !== 'rawGameCreated' &&
        (runState[EngineRunStateOrder[EngineRunStateOrder[key] - 1] as EngineRunStateItem] ||
          PARALLEL_TASKS.includes(key)) ? (
        <>
          <img className={classNames(styles.statusIndicator, styles.spinWithIntervals)} alt='' src={InProgressIcon} />
          <div>{statusLabels[key]}</div>
          <div className={styles.statusShortLabel}>{t('engine.status_label.processing', 'Processing')}</div>
        </>
      ) : (
        <>
          <div className={styles.statusIndicator} />
          <div>{statusLabels[key]}</div>
          <div className={styles.statusShortLabel}>{t('engine.status_label.todo', 'Todo')}</div>
        </>
      )
    },
    [runState],
  )
  if (!runState) {
    return <div />
  }

  return (
    <div className={styles.stepWrapper}>
      <div className={styles.stepTitle}>{t('engine.login_step.lets_create', "Let's create your game")}</div>
      <Instructions initialOpen={false} />
      <div>
        {Object.keys(statusLabels).map((key) => {
          return (
            <>
              <div key={key} className={styles.statusRow}>
                {statusRowFor(key as EngineRunStateItem)}
              </div>
              {key === 'rawGameCreated' && runState[key] && (
                <div className={styles.tasksDoneLabel}>
                  {t('engine.status_label.tasks_done', 'Tasks done, now generating story and board options')}
                </div>
              )}
            </>
          )
        })}
      </div>
    </div>
  )
}
