import React, { useEffect, useRef } from 'react'
import { useGameData } from '../../contexts/SponsoredGamesContextProvider'
import styles from '../sponsored/Sponsored.module.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getFocusableGameItemId } from '../../composites/GamesOverview/helpers'
import { LegacyEditorVersion, setLegacyEditorVersion } from '../../util/editorPreference'
import { Sponsored } from '../sponsored/Sponsored'
import { LibraryInfo } from '../../common/components/LibraryInfo/LibraryInfo'

export const SponsoredTop10Page: React.FC = () => {
  const { currentPage } = useGameData()
  const gameData = useGameData()
  const { t } = useTranslation()

  const lastCurrentPage = useRef<number>(currentPage)

  useEffect(() => {
    if (currentPage !== lastCurrentPage.current) {
      const firstGameElement = document.querySelector<HTMLElement>(`[id^=${getFocusableGameItemId('')}]`)
      firstGameElement?.focus({ preventScroll: true })
      if ((firstGameElement?.getBoundingClientRect().top ?? 0) < 0) {
        firstGameElement?.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' })
      }
    }
    lastCurrentPage.current = currentPage
  }, [currentPage])

  useEffect(() => {
    setLegacyEditorVersion(LegacyEditorVersion.LEGACY_FRAME)
  }, [])

  return (
    <div className={classNames(styles.contentContainerInstructor)}>
      <div className={styles.headerContainer}>
        {/*user && <button onClick={handleSilentLogout}>Click to logout - will be done automatically normally</button>*/}
        <div className={styles.bannerContainer}>
          <div className={styles.introBanner}>
            <LibraryInfo infoTitle={t('sponsored_games.top_10_title', 'Sponsored Games Top 10')} showCarousel={false}>
              <div className={styles.firstBannerBody}>
                {t(
                  'sponsored_games.top_10_body_1',
                  'Dive into our magical collection of sponsored Seppo games! Here you can find the most downloaded sponsored games.',
                )}
              </div>
              <div className={styles.firstBannerBody}>
                {t(
                  'sponsored_games.top_10_body_2',
                  'P.S. You can usually find the games activation code on the game creator´s website.',
                )}
              </div>
            </LibraryInfo>
          </div>
        </div>
      </div>
      <Sponsored isInstructorView={true} gameData={gameData} top10Mode />
    </div>
  )
}
